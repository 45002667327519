export const ACTIVE_PROJECT_KEY: string = 'activeProject'
export const ACTIVE_ACCOUNT_KEY: string = 'activeAccount'

export const ORDER_FILTER_KEY: string = 'OrderActiveFilter'
export const INTEGRATOR_FILTER_KEY: string = 'IntegratorActiveFilter'
export const TEAM_FILTER_KEY: string = 'TeamActiveFilter'
export const PRODUCT_FILTER_KEY: string = 'ProductActiveFilter'
export const INVENTORY_FILTER_KEY: string = 'InventoryActiveFilter'
export const LOCATION_FILTER_KEY: string = 'LocationActiveFilter'
export const WAREHOUSE_FILTER_KEY: string = 'WarehouseActiveFilter'

export const ORDER_SORT_KEY: string = 'OrderActiveSort'
export const INTEGRATOR_SORT_KEY: string = 'IntegratorActiveSort'
export const TEAM_SORT_KEY: string = 'TeamActiveSort'
export const PRODUCT_SORT_KEY: string = 'ProductActiveSort'
export const INVENTORY_SORT_KEY: string = 'InventoryActiveSort'
export const LOCATION_SORT_KEY: string = 'LocationActiveSort'
export const WAREHOUSE_SORT_KEY: string = 'WarehouseActiveSort'

export const INVENTORY_WAREHOUSE_KEY: string = 'InventoryWarehouseFilter'
export const ALL_WAREHOUSES: string = 'All warehouses'