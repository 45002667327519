import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'

@Injectable( {
   providedIn: 'root'
} )
export class NavigationDataService {
   // tslint:disable-next-line:typedef
   private messageSource = new BehaviorSubject<string>( 'default_msg' )
   // tslint:disable-next-line:typedef
   public currentMessage = this.messageSource.asObservable()
   
   //  ******* switch cluster ********
   // tslint:disable-next-line:typedef
   // private triggerSource = new BehaviorSubject<string>( 'default_switch_position' )
   // tslint:disable-next-line:typedef
   // public currentTriggerState = this.triggerSource.asObservable()
   
   constructor() {
   }
   
   /**
    * If user click on any link on the nav-bar, update event
    * @param msg
    */
   public changeMessage( msg: string ) {
      this.messageSource.next( msg )
   }
   
   // public updateSwithcState( switchPosition: string ) {
   //    this.triggerSource.next( switchPosition )
   // }
}
