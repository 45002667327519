import { InMemoryDbService } from 'angular-in-memory-web-api'
import { ProjectDashboardDb } from 'app/fake-db/dashboard-project'
import { LandingDomesticDb } from 'app/fake-db/landing-domestic'

import { ShipmentsFakeDb } from 'app/fake-db/tracking-shipments'

import { ShipmentFakeDb } from './shipment'
import { TesterProducts } from './tester-products'

export class FakeDbService implements InMemoryDbService {
   
   public createDb(): any {
      
      return {
         // Dashboards
         'project-dashboard-projects': ProjectDashboardDb.projects,
         'project-dashboard-widgets' : ProjectDashboardDb.widgets,
         // 'analytics-dashboard-widgets': AnalyticsDashboardDb.widgets,
         
         // Landing
         'landing-domestic-projects': LandingDomesticDb.projects,
         'landing-domestic-widgets' : LandingDomesticDb.widgets,
         // 'landing-international-projects': LandingInternationalDb.projects,
         // 'landing-international-widgets': LandingInternationalDb.widgets,
         
         // Shipments
         // 'tracker-shipments': TrackerShipmentDb.shipments,
         // 'tracker-shipments-widgets': TrackerShipmentDb.widgets,
         // 'tracker-shipments': ShipmentsFakeDb.products
         'tracking-shipments': ShipmentsFakeDb.shipments,
         
         'shipment-data': ShipmentFakeDb.shipmentData,
         
         // Accounts tester
         'test-products' : TesterProducts.products
         
      }
   }
}

