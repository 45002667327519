import { Component, OnInit, OnDestroy, ViewChild, EventEmitter } from '@angular/core'
import { Subject } from 'rxjs'
import { NavigationDataService } from './navigation-data.service'
import { User } from '@shared/core/models/user.model'
import { faSearchLocation, faHandsHelping, faUserCircle, faGlobe, faMapMarked, faShippingTimed, faTachometerAlt, faHomeLg, IconDefinition } from '@fortawesome/pro-solid-svg-icons'
import { takeUntil } from 'rxjs/operators'
import { SidenavComponent, UploadFile, UploadInput, ModalDirective, ToastService, UploadOutput } from 'ng-uikit-pro-standard'
import { TeamsService } from 'app/main/apps/sgl-client-portal/services/teams.service'
import { KeyValuePair } from '../../main/apps/sgl-client-portal/models/generic/key-value-pair'
import { FormGroup } from '@angular/forms'
import { environment } from '@env/environment'
import { Router } from '@angular/router'
import { ACTIVE_ACCOUNT_KEY, ACTIVE_PROJECT_KEY } from 'app/main/apps/sgl-client-portal/constants/local-storage'
import { ConfigurationService } from '@shared/core/services/configuration.service'
import { ProjectsService } from 'app/main/apps/sgl-client-portal/services/projects.service'
import { SuddathAuthenticationService } from '@suddath/authentication'
import { SuddathAuthorizationService } from '@suddath/authorization'
import { Roles } from '@shared/core/constants/Roles'

@Component({
    selector: 'app-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit, OnDestroy {
    @ViewChild(SidenavComponent, { static: false }) sidenav: SidenavComponent
    @ViewChild('uploadIntegratorDatesModal', { static: false }) uploadIntegratorDatesModal: ModalDirective

    clicked: boolean

    // icons
    faSearchLocation: IconDefinition = faSearchLocation
    // public faFlagCheckered: IconDefinition = faFlagCheckered
    faHandsHelping: IconDefinition = faHandsHelping
    faUserCircle: IconDefinition = faUserCircle
    faGlobe: IconDefinition = faGlobe
    faMapMarked: IconDefinition = faMapMarked
    faShippingTimed: IconDefinition = faShippingTimed
    faTachometerAlt: IconDefinition = faTachometerAlt
    faHomeLg: IconDefinition = faHomeLg

    // auth
    // public isAuthorizedSubscription: Subscription
    isAuthorized: boolean = false
    // public userSubscription: Subscription
    currentUser: User
    currentUserRoleNames: string[] = []

    hasTransfereeRole: boolean = false
    initials: string

    // nav data
    message: string
    accountOptionsList: KeyValuePair<number, string>[]
    projectOptionsList: KeyValuePair<number, string>[]
    selectedProjectId: number = 0
    selectedAccountId: number = 0
    supportsInventory: boolean

    // TODO.JW: Remove flag in future release and its UI coupled logic
    warehouseUpdateFlag: boolean = false
    // TODO remove feature flag
    inventoryPageFlag: boolean = false
    unsubscribe: Subject<void> = new Subject<void>()

    integratorDataFileDragOver: boolean
    showIntegratorDataUploadSpinner: boolean = false
    integratorDataFiles: UploadFile[]
    addIntegratorFileDataForm: FormGroup
    uploadIntegratorDatesInput: EventEmitter<UploadInput>

    /**
     * Constructor
     *
     * @param navigationDataService
     * @param _userService
     */
    constructor(
        private navigationDataService: NavigationDataService,
        private _userService: SuddathAuthenticationService,
        private _teamsService: TeamsService,
        private _projectsService: ProjectsService,
        private toastrService: ToastService,
        private configurationService: ConfigurationService,
        private router: Router,
        private _suddathAuthorizationService: SuddathAuthorizationService
    ) {
        this.integratorDataFiles = []
        this.uploadIntegratorDatesInput = new EventEmitter<UploadInput>()
    }

    /**
     *  On Init
     */
    ngOnInit() {
        this.navigationDataService.currentMessage.pipe(takeUntil(this.unsubscribe)).subscribe(message => (this.message = message))
        this._userService.isAuthorized$.pipe(takeUntil(this.unsubscribe)).subscribe(isAuth => {
            this.isAuthorized = !!isAuth
            if (isAuth) {
                this.configurationService.loadConfiguration().pipe(takeUntil(this.unsubscribe)).subscribe(() => {
                    this.getAccounts()

                    // TODO.JW: Remove flag in future release
                    this.warehouseUpdateFlag = environment.featureFlags.inventoryWarehouseUpdate
                    // TODO remove feature flag
                    this.inventoryPageFlag = environment.featureFlags.inventoryPage
                })
            }
        })

        this._userService.currentUser$.pipe(takeUntil(this.unsubscribe)).subscribe(user => {
            this.initials = this._userService.currentUser
            this.currentUser = user
            if (user) {
                this._suddathAuthorizationService.getUserRoles().pipe(takeUntil(this.unsubscribe)).subscribe(currentUserRoleNames => {
                    this._suddathAuthorizationService.userRoles = currentUserRoleNames?.roles?.map(role => role?.name);
                    this.currentUserRoleNames = currentUserRoleNames?.roles?.map(role => role?.name)
                })
            }
        })

        this._teamsService.userProject$.pipe(takeUntil(this.unsubscribe)).subscribe(response => {
            this.supportsInventory = response.supportsInventory
        })

        this.setupAddIntegratorDataFormGroup()

        this._teamsService.updateAccountsList$.subscribe(() => {
            const localAccountString = localStorage.getItem(ACTIVE_ACCOUNT_KEY)
            const localProjectString = localStorage.getItem(ACTIVE_PROJECT_KEY)

            let activeAccount: KeyValuePair<number, string>

            if (localAccountString) {
                activeAccount = JSON.parse(localAccountString) as KeyValuePair<number, string>
            }

            this._teamsService.getAccounts().subscribe(t => {
                //refresh the dropdown list with the updates
                this.accountOptionsList = t

                localStorage.setItem(ACTIVE_ACCOUNT_KEY, JSON.stringify(t.find(x => x.key === activeAccount.key)))

                //get project for active account
                let activeProject: KeyValuePair<number, string>

                if (localProjectString) {
                    activeProject = JSON.parse(localProjectString) as KeyValuePair<number, string>
                }

                this.getProjects(activeAccount.key)

                localStorage.setItem(ACTIVE_PROJECT_KEY, JSON.stringify(this.projectOptionsList.find(x => x.key === activeProject.key)))
            })
        })
    }

    getAccounts() {
        this._teamsService
            .getAccounts()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(accounts => {
                this.accountOptionsList = accounts
                this._teamsService
                    .getActiveAccount()
                    .pipe(takeUntil(this.unsubscribe))
                    .subscribe(activeAccount => {
                        if (!this.accountOptionsList.find(t => t.key === activeAccount.key)) {
                            //no account match so clear everything and reload
                            localStorage.removeItem(ACTIVE_ACCOUNT_KEY)
                            localStorage.removeItem(ACTIVE_PROJECT_KEY)

                            this.getAccounts()
                        }

                        this.selectedAccountId = activeAccount.key

                        this.getProjects(this.selectedAccountId)
                    })
            })
    }

    getProjects(selectedAccountId: number) {
        return this._teamsService
            .getProjects()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(projects => {
                this.projectOptionsList = projects
                this._teamsService
                    .getActiveProject()
                    .pipe(takeUntil(this.unsubscribe))
                    .subscribe(activeProject => {
                        this.selectedProjectId = activeProject.key
                        this._teamsService
                            .getUserProject()
                            .subscribe(response => {
                                this.supportsInventory = response.supportsInventory
                            })
                    })
            })
    }

    /**
     * newMessage:  update nav data value, so subcribers get new value
     * @param msg
     */
    newMessage(msg: string) {
        this.navigationDataService.changeMessage(msg)
    }

    login() {
        this._userService.login()
    }

    logout() {
        this._userService.logoff()
    }

    onChangeActiveProject() {
        this._teamsService.changeActiveProject(this.projectOptionsList.filter(p => p.key === this.selectedProjectId)[0])
        this._teamsService
            .getUserProject()
            .subscribe(response => {
                this.supportsInventory = response.supportsInventory
            })

        this.router.navigate(['/apps/sglclientportal/orders'])
    }

    onChangeActiveAccount(e: any) {
        let selectedAccount = this.accountOptionsList.filter(p => p.key === this.selectedAccountId)[0]

        this._teamsService.changeActiveAccount(selectedAccount, () => {
            this._teamsService
                .getProjects()
                .pipe(takeUntil(this.unsubscribe))
                .subscribe(projects => {
                    this.projectOptionsList = projects
                    const firstProject = this.projectOptionsList[0]

                    this.selectedProjectId = firstProject.key
                    this._teamsService
                        .getUserProject()
                        .subscribe(response => {
                            this.supportsInventory = response.supportsInventory
                        })

                    this._teamsService.changeActiveProject(firstProject)

                    //TODO.JD - is this what we want?
                    this.router.navigate(['/apps/sglclientportal/orders'])
                })
        })
    }

    ngOnDestroy(): void {
        this.unsubscribe.next()
        this.unsubscribe.complete()
    }

    startIntegratorDatesUpload(): void {
        const baseUrl = environment.sgl.base_url
        const requestUrl = `${baseUrl}Orders/UploadIntegratorDataFile`

        if (this.integratorDataFiles.length === 0) {
            this.toastrService.error('Please select a file.')
            return
        }

        const file = this.integratorDataFiles[0] ? this.integratorDataFiles[0].nativeFile : undefined

        const event: UploadInput = {
            type: 'uploadAll',
            url: requestUrl,
            method: 'POST',
            data: { file },
            headers: { Authorization: `Bearer ${this._userService.getBearerToken()}` }
        }
        this.integratorDataFiles = []
        this.uploadIntegratorDatesInput.emit(event)
        this.showIntegratorDataUploadSpinner = true
    }

    cancelIntegratorDatesUpload(id: string): void {
        this.uploadIntegratorDatesInput.emit({ type: 'cancel', id: id })
        this.showIntegratorDataUploadSpinner = false
    }

    showIntegratorDataFiles() {
        let files = ''
        for (let i = 0; i < this.integratorDataFiles.length; i++) {
            files += this.integratorDataFiles[i].name
            if (!(this.integratorDataFiles.length - 1 === i)) {
                files += ','
            }
        }
        return files
    }

    onUploadIntegratorDatesOutput(output: UploadOutput | any): void {
        if (output.type === 'allAddedToQueue') {
        } else if (output.type === 'addedToQueue') {
            if (output.file.name.split('.').pop() === 'csv') {
                this.integratorDataFiles.push(output.file) // add file to array when added
            } else {
                this.toastrService.error('File format is invalid; please update and re-submit.')
            }
        } else if (output.type === 'uploading') {
            // update current data in files array for uploading file
            const index = this.integratorDataFiles.findIndex(file => file.id === output.file.id)
            this.integratorDataFiles[index] = output.file
        } else if (output.type === 'removed') {
            // remove file from array when removed
            this.integratorDataFiles = this.integratorDataFiles.filter((file: UploadFile) => file !== output.file)
        } else if (output.type === 'dragOver') {
            this.integratorDataFileDragOver = true
        } else if (output.type === 'dragOut') {
        } else if (output.type === 'drop') {
            this.integratorDataFileDragOver = false
        } else if (output.type === 'done') {
            const file: UploadFile = output.file

            if (file !== null) {
                console.log(file)
                if (file.responseStatus !== 200) {
                    this.toastrService.error('Error uploading file. See console for details.')

                    if (file.response) {
                        console.error(file.response)
                    }

                    this.showIntegratorDataUploadSpinner = false
                } else {
                    this.toastrService.success('File uploaded successfully!')

                    this.closeIntegratorUploadModal()

                    //this.ordersService.refreshOrderDetails.emit(true)
                }

                this.integratorDataFiles = []
            }
        }
        this.showIntegratorDataFiles()
    }

    closeIntegratorUploadModal() {
        this.integratorDataFiles = []
        this.uploadIntegratorDatesModal.hide()
        this.showIntegratorDataUploadSpinner = false
    }

    setupAddIntegratorDataFormGroup() {
        this.addIntegratorFileDataForm = new FormGroup({})
    }

    onOpenUploadIntegratorDatesModal(event: any) { }

    hasRole(roleName: string): boolean {
        return this._suddathAuthorizationService.hasRole(roleName)
    }

    get userRole(): string {
        return Roles.User
    }
    get superUserRole(): string {
        return Roles.SuperUser
    }
}
