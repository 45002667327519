import { ToastModule } from 'ng-uikit-pro-standard'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import {
    NgModule,
    NO_ERRORS_SCHEMA,
    CUSTOM_ELEMENTS_SCHEMA,
    APP_INITIALIZER
} from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { RouterModule, Routes } from '@angular/router'
import { HttpClientModule } from '@angular/common/http'

import { AppComponent } from './app.component'
import { SharedModule } from '@shared/shared.module'
import { MDBSpinningPreloader } from 'ng-uikit-pro-standard'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'

// main layout
import { NavigationModule } from './main-layout/navigation/navigation.module'

/* OIDC Client */
import {
    AuthModule,
    OidcConfigService,
    OidcSecurityService,
    OpenIdConfiguration
} from 'angular-auth-oidc-client'

import { environment } from '@env/environment'

// in memory web api
import { InMemoryWebApiModule } from 'angular-in-memory-web-api'
import { FakeDbService } from './fake-db/fake-db.service'
import { AuthConfigurationService } from '@shared/core/services/auth-configuration.service'
import { switchMap } from 'rxjs/operators'
import { AuthorizationModule } from '@suddath/authorization'
import { AuthenticationModule } from '@suddath/authentication'

const appRoutes: Routes = [
    {
        path: 'loader',
        loadChildren: () =>
            import('./shared/shared.module').then(m => m.SharedModule),
    },
    {
        path: 'apps',
        loadChildren: () =>
            import('./main/apps/apps.module').then(m => m.AppsModule),
    },
    {
        path: 'ui',
        loadChildren: () =>
            import('./main/ui/ui.module').then(m => m.UiModule),
    },
    {
        path: '**',
        redirectTo: '/loader'
    }
]

const AuthorizationModuleOptions: {
    policyUrl: string
    searchType: string
    clientId: string
} = {
    policyUrl: '',
    searchType: '',
    clientId: ''
}


/**
 * Support SunGate v2.0 integration
 * @param oidcConfigService
 */
export function loadConfig(authConfigurationService: AuthConfigurationService, oidcConfigService: OidcConfigService) {
    return () => authConfigurationService.loadAuthConfiguration()
        .pipe(
            switchMap(() => {
                console.log('APP_INITIALIZER STARTING: loading custom well known endpoints from ' + environment.sts.config.wellKnownEndpoints)
                setConfig(oidcConfigService);
                return '';
            })
        ).toPromise()
}

const setConfig = (oidcConfigService: OidcConfigService): void => {

    const openIdConfig: OpenIdConfiguration = {
        stsServer: environment.sts.config.stsServer,
        redirectUrl: environment.sts.config.redirect_url,
        clientId: environment.sts.config.client_id,
        responseType: environment.sts.config.response_type,
        scope: environment.sts.config.scope,
        postLogoutRedirectUri:
            environment.sts.config.post_logout_redirect_uri,
        startCheckSession:
            environment.sts.config.start_checksession,
        silentRenew: environment.sts.config.silent_renew,
        postLoginRoute: environment.sts.config.startup_route,
        forbiddenRoute: environment.sts.config.forbidden_route,
        unauthorizedRoute:
            environment.sts.config.unauthorized_route,
        maxIdTokenIatOffsetAllowedInSeconds:
            environment.sts.config
                .max_id_token_iat_offset_allowed_in_seconds,
        authWellknownEndpoint: environment.sts.config.wellKnownEndpoints
    }


    openIdConfig.silentRenewUrl = `${window.location.origin}/silent-renew.html`;
    openIdConfig.storage = localStorage;

    oidcConfigService.withConfig(openIdConfig)

    AuthorizationModuleOptions.policyUrl = environment.auth?.policy_server_url,
    AuthorizationModuleOptions.searchType = environment.auth?.search_type,
    AuthorizationModuleOptions.clientId = environment.auth?.client_id

    console.log(
        `AppModule: Security configuration loaded. STS Server: ${environment.sts.config.stsServer}`
    )
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        NavigationModule,
        HttpClientModule,
        AuthModule.forRoot(),
        AuthorizationModule.forRoot(
            AuthorizationModuleOptions),
        AuthenticationModule,
        RouterModule.forRoot(appRoutes),
        InMemoryWebApiModule.forRoot(FakeDbService, {
            delay: 0,
            passThruUnknownUrl: true
        }),
        FormsModule,
        SharedModule,
        ToastModule.forRoot(),
        ReactiveFormsModule,
        FontAwesomeModule
    ],
    providers: [
        MDBSpinningPreloader,
        OidcConfigService,
        {
            provide: APP_INITIALIZER,
            useFactory: loadConfig,
            deps: [AuthConfigurationService, OidcConfigService],
            multi: true
        },
        OidcSecurityService
    ],
    bootstrap: [AppComponent],
    schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
    constructor(
        private oidcSecurityService: OidcSecurityService,
        private oidcConfigService: OidcConfigService
    ) {
    }
}
