import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { Location } from '@angular/common'
import { OidcSecurityService } from 'angular-auth-oidc-client'
import { FuseSplashScreenService } from '../@fuse/services/splash-screen.service'
import { SuddathAuthenticationService } from '@suddath/authentication'

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.css']
})

/**
 * AppComponent
 */
export class AppComponent implements OnInit {
    // auth
    isAuthorized: boolean = false

    /**
     * Constructor
     *
     * @param _router
     * @param _location
     * @param _oidcSecurityService
     * @param _userService
     * @param _fuseSplashScreenService
     */
    constructor(
        private _router: Router,
        private _location: Location,
        private oidcSecurityService: OidcSecurityService,
        private _userService: SuddathAuthenticationService,
        private _fuseSplashScreenService: FuseSplashScreenService
    ) {
        // SunGate functionality support
        // if (this._oidcSecurityService.moduleSetup) {
        //     //
        //     this.doCallBackLogicIfRequired()
        // } else {
        //     this._oidcSecurityService.onModuleSetup.subscribe(() => {
        //         this.doCallBackLogicIfRequired()
        //     })
        // }
    }

    /**
     * OnInit event
     */
    ngOnInit(): void {
        this.oidcSecurityService.checkAuthIncludingServer().subscribe((isAuthenticated) => console.log('app authenticated', isAuthenticated));
        /*
        this._userService.isAuthorized$.subscribe(
            isAuth => (this.isAuthorized = isAuth)
        )
        */
    }

    /**
     * goBack
     */
    goBack(): void {
        this._location.back()
    }

    /**
     * SunGate support
     */
    private doCallBackLogicIfRequired() {
        if (window.location.hash) {
            // this._oidcSecurityService.authorizedImplicitFlowCallback()
        }
    }
}
