<!--Double navigation-->
<header *ngIf="currentUserRoleNames?.length">
    <!-- Sidebar navigation -->
    <mdb-side-nav #sidenav class="fixed new-side-nav border-right" *ngIf="isAuthorized" [sidenavBreakpoint]="1200">
        <div class="close-sidebar text-right mt-2">
            <button class="btn btn-link btn-sm" (click)="sidenav.toggle()">
                <mdb-icon fas icon="times"></mdb-icon>
            </button>
        </div>
        <div *ngIf="isAuthorized" class="container">
            <div class="row">
                <div class="col text-center">
                    <!-- <button id="small-user-badge" class="badge-hover mt-xl-5" type="button">
                        {{ initials | uppercase }}
                    </button> -->
                    <img routerLink="apps/sglclientportal/orders" id="simpl-logo" src="assets/img/simpl_logo.svg"/>
                </div>
            </div>
            <div class="row">
                <div class="col text-center">
                    <h4 class="mt-3 user-name">
                        {{ currentUser.given_name }}
                        {{ currentUser.family_name }}
                    </h4>
                </div>
            </div>
            <div class="row">
                <div class="col text-center">
                    <button mdbBtn flat="true" size="sm" (click)="logout()" style="color: #232323 !important">
                        Logout
                    </button>
                </div>
            </div>
        </div>

        <links>
            <li>
                <ul class="collapsible collapsible-accordion">
                    <!-- nav items -->
                    <mdb-accordion [multiple]="false" aria-multiselectable="false">
                        <mdb-accordion-item class="no-collase">
                            <mdb-accordion-item-head routerLink="apps/sglclientportal/orders">
                                Orders
                            </mdb-accordion-item-head>

                            <mdb-accordion-item-body></mdb-accordion-item-body>
                        </mdb-accordion-item>

                        <mdb-accordion-item class="no-collase" *ngIf="hasRole(userRole) || hasRole(superUserRole)">
                            <mdb-accordion-item-head routerLink="apps/sglclientportal/configurations">
                                Configurations
                            </mdb-accordion-item-head>

                            <mdb-accordion-item-body></mdb-accordion-item-body>
                        </mdb-accordion-item>

                        <mdb-accordion-item class="no-collase" *ngIf="hasRole(userRole) || hasRole(superUserRole)">
                            <mdb-accordion-item-head routerLink="apps/sglclientportal/team">
                                Team
                            </mdb-accordion-item-head>

                            <mdb-accordion-item-body></mdb-accordion-item-body>
                        </mdb-accordion-item>

                        <mdb-accordion-item class="no-collase" *ngIf="hasRole(userRole) || hasRole(superUserRole)">
                            <mdb-accordion-item-head routerLink="apps/sglclientportal/catalog">
                                Product Catalog
                            </mdb-accordion-item-head>

                            <mdb-accordion-item-body></mdb-accordion-item-body>
                        </mdb-accordion-item>

                        <!--TODO: remove feature flag-->
                        <ng-template [ngIf]="inventoryPageFlag && supportsInventory">
                            <mdb-accordion-item class="no-collase" *ngIf="hasRole(userRole) || hasRole(superUserRole)">
                                <mdb-accordion-item-head routerLink="apps/sglclientportal/inventory">
                                    Product Inventory
                                </mdb-accordion-item-head>

                                <mdb-accordion-item-body></mdb-accordion-item-body>
                            </mdb-accordion-item>
                        </ng-template>

                        <mdb-accordion-item class="no-collase" *ngIf="hasRole(userRole) || hasRole(superUserRole)">
                            <mdb-accordion-item-head routerLink="apps/sglclientportal/locations">
                                Locations
                            </mdb-accordion-item-head>

                            <mdb-accordion-item-body></mdb-accordion-item-body>
                        </mdb-accordion-item>

                        <!--TODO: remove feature flag-->
                        <ng-template [ngIf]="warehouseUpdateFlag && supportsInventory">
                            <mdb-accordion-item class="no-collase" *ngIf="hasRole(userRole) || hasRole(superUserRole)">
                                <mdb-accordion-item-head routerLink="apps/sglclientportal/warehouses">
                                    Warehouses
                                </mdb-accordion-item-head>

                                <mdb-accordion-item-body></mdb-accordion-item-body>
                            </mdb-accordion-item>
                        </ng-template>

                        <mdb-accordion-item class="no-collase">
                            <mdb-accordion-item-head routerLink="apps/sglclientportal/reports">
                                Reports
                            </mdb-accordion-item-head>

                            <mdb-accordion-item-body></mdb-accordion-item-body>
                        </mdb-accordion-item>
                    </mdb-accordion>
                </ul>
            </li>
            <!--/. Side navigation links -->
        </links>
        <div class="sidenav-bg mask-strong"></div>
    </mdb-side-nav>
    <!--/. Sidebar navigation -->

    <!-- horizontal Navbar -->
    <mdb-navbar id="home" SideClass="navbar navbar-toggleable-md navbar-expand-md scrolling-navbar double-nav" [containerInside]="false">
        <div class="hamburger">
            <a (click)="sidenav.toggle()" class="button-collapse hidden-nav-button-collapse">
                <i class="fas fa-bars"></i>
            </a>
        </div>

        <span class="font-weight-bold pl-3">Suddath</span>

        <navlinks>
            <ul class="nav navbar-nav nav-flex-icons ml-auto ie-double-nav ie-hidden-double-nav">
                <li *ngIf="hasRole(userRole) || hasRole(superUserRole)" class="nav-item dropdown btn-group">
                    <button mdbBtn flat="true" color="default" routerLink="apps/sglclientportal/team/create"><mdb-icon fas icon="plus" class="mr-1"></mdb-icon> Add New Team Member</button>
                </li>
                <li *ngIf="hasRole(userRole) || hasRole(superUserRole)" class="nav-item dropdown btn-group">
                    <button mdbBtn flat="true" color="default" routerLink="apps/sglclientportal/locations/create"><mdb-icon fas icon="plus" class="mr-1"></mdb-icon> Add Location</button>
                </li>
                <li *ngIf="hasRole(superUserRole)" class="nav-item dropdown btn-group mr-3">
                    <button mdbBtn flat="true" color="default" routerLink="apps/sglclientportal/accounts/create"><mdb-icon fas icon="plus" class="mr-1"></mdb-icon> Add Account</button>
                </li>
                <li *ngIf="hasRole(superUserRole)" class="nav-item dropdown btn-group mr-3">
                    <button mdbBtn flat="true" color="default" routerLink="apps/sglclientportal/accounts/edit/{{selectedAccountId}}"><mdb-icon fas icon="plus" class="mr-1"></mdb-icon> Edit Account</button>
                </li>
                <li *ngIf="!isAuthorized" (click)="login()">
                    <a type="button" class="nav-link waves-light" mdbWavesEffect>
                        <fa-icon class="mr-1 fa-2x black-icon" [icon]="faUserCircle"></fa-icon>
                        <span class="clearfix d-none d-sm-inline-block navbar-user-name">login</span>
                    </a>
                </li>
                <li>
                    <mat-form-field class="mr-3">
                        <mat-select [(value)]="selectedAccountId" (selectionChange)="onChangeActiveAccount($event)">
                            <mat-option *ngFor="let option of accountOptionsList" [value]="option.key">{{ option.value }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-select [(value)]="selectedProjectId" (selectionChange)="onChangeActiveProject()">
                            <mat-option *ngFor="let option of projectOptionsList" [value]="option.key">{{ option.value }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </li> 
            </ul>
        </navlinks>
    </mdb-navbar>
    <!--/. Navbar -->

    <div mdbModal #uploadIntegratorDatesModal="mdbModal" (open)="onOpenUploadIntegratorDatesModal($event)" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="uploadIntegratorDatesLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close pull-right" aria-label="Close" (click)="uploadIntegratorDatesModal.hide()">
                        <span aria-hidden="true">×</span>
                    </button>
                    <h4 class="modal-title w-100">Upload Integrator Dates</h4>
                </div>
                <div class="modal-body">
                    <form [formGroup]="addIntegratorFileDataForm">
                        <div class="file-field md-form">
                          <div mdbBtn color="primary" size="sm" class="waves-light" mdbWavesEffect>
                            <span>Choose file</span>
                            <input type="file" mdbFileSelect (uploadOutput)="onUploadIntegratorDatesOutput($event)" [uploadInput]="uploadIntegratorDatesInput">
                          </div>
                          <div class="file-path-wrapper">
                            <input class="file-path" type="text" placeholder="Upload your file" [value]="showIntegratorDataFiles()">
                          </div>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button type="button" mdbBtn color="secondary" class="waves-light" aria-label="Close" (click)="closeIntegratorUploadModal()" mdbWavesEffect>Close</button>
                    <button mdbBtn color="primary" (click)="startIntegratorDatesUpload()"><span *ngIf="showIntegratorDataUploadSpinner" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>  Start uploading</button>
                </div>
            </div>
        </div>
    </div>
</header>
<!--/.Double navigation-->
