import { ModalComponent } from './components/modal/modal.component'
import { MDBBootstrapModulesPro } from 'ng-uikit-pro-standard'
import {
    NgModule,
    NO_ERRORS_SCHEMA,
    CUSTOM_ELEMENTS_SCHEMA
} from '@angular/core'
import { CommonModule } from '@angular/common'
import { AlertComponent } from './alerts/alert/alert.component'
import { CascadingPanelComponent } from './components/cascading-panel/cascading-panel.component'
import { CascadingCardComponent } from './components/cascading-card/cascading-card.component'
import { OverlayCardComponent } from './components/overlay-card/overlay-card.component'
import { PanelComponent } from './components/panel/panel.component'
import { NotificationComponent } from './components/notification/notification.component'
import { NotificationService } from './components/notification/notification.service'
import { LoaderComponent } from './loader/loader/loader.component'
import { RouterModule, Routes } from '@angular/router'
import { ErrorComponent } from './error/error.component'
import { LoaderSupportComponent } from './loader/loader-support/loader-support.component'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MaterialModule } from '@shared/material.module'
import { SuddathAuthenticationService } from '@suddath/authentication'

const routes: Routes = [
    {
        path: 'loader',
        component: LoaderComponent
    },
    {
        path: 'support/:id',
        component: LoaderSupportComponent
    },
    {
        path: 'error',
        component: ErrorComponent
    }
]

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MDBBootstrapModulesPro.forRoot(),
        RouterModule.forChild(routes),
        MaterialModule
    ],
    declarations: [
        AlertComponent,
        CascadingPanelComponent,
        CascadingCardComponent,
        OverlayCardComponent,
        PanelComponent,
        ModalComponent,
        NotificationComponent,
        LoaderComponent,
        ErrorComponent,
        LoaderSupportComponent
    ],
    exports: [
        MDBBootstrapModulesPro,
        CascadingPanelComponent,
        CascadingCardComponent,
        OverlayCardComponent,
        PanelComponent,
        ModalComponent,
        NotificationComponent
    ],
    providers: [NotificationService, SuddathAuthenticationService],
    schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule {}
