import { Injectable } from '@angular/core'
import { Observable, Subscription, Subject, forkJoin } from 'rxjs'
import { environment } from '@env/environment'
import { OidcSecurityService } from 'angular-auth-oidc-client'
import { HttpHeaders, HttpClient } from '@angular/common/http'
import { KeyValuePair } from '../models/generic/key-value-pair'
import { UsersListResponse } from '../models/team/users-list-response'
import { UserConfiguration } from '../models/team/user-configuration'
import { ACTIVE_ACCOUNT_KEY, ACTIVE_PROJECT_KEY } from '../constants/local-storage'
import { UserProject } from '../models/response/user-project-view-response'

@Injectable({
    providedIn: 'root'
})
export class TeamsService {
    private updateAccountsListSource = new Subject()
    updateAccountsList$ = this.updateAccountsListSource.asObservable()

    supportsInventory: boolean

    constructor(private _httpClient: HttpClient, private _oidcSecurityService: OidcSecurityService) { }

    updateAccountList() {
        this.updateAccountsListSource.next('')
    }

    getUsers(page: number, filter: string = '', pageSize: number = 20, sortField: string = '', sortDescending: boolean = false): Observable<UsersListResponse> {
        const tokenId = this._oidcSecurityService.getToken()

        const href = environment.sgl.users_url
        const requestUrl = `${href}`

        const params = {
            page: page.toString(),
            filter: filter,
            pageSize: pageSize.toString(),
            sortField: sortField,
            sortDescending: `${sortDescending}`
        }

        // http req header
        const requestHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + tokenId
        })

        return this._httpClient.get<UsersListResponse>(requestUrl, {
            headers: requestHeaders,
            params: params
        })
    }

    getUser(id: number): Observable<UserConfiguration> {
        const tokenId = this._oidcSecurityService.getToken()

        const href = environment.sgl.users_url
        const requestUrl = `${href}/${id}`

        // http req header
        const requestHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + tokenId
        })

        return this._httpClient.get<UserConfiguration>(requestUrl, {
            headers: requestHeaders
        })
    }

    getRoles(): Observable<KeyValuePair<number, string>[]> {
        const tokenId = this._oidcSecurityService.getToken()

        const href = environment.sgl.users_url
        const requestUrl = `${href}/roles`

        // http req header
        const requestHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + tokenId
        })

        return this._httpClient.get<KeyValuePair<number, string>[]>(requestUrl, {
            headers: requestHeaders
        })
    }

    saveMember(user: UserConfiguration, create: boolean): Observable<any> {
        const tokenId = this._oidcSecurityService.getToken()

        const href = environment.sgl.users_url
        const requestUrl = `${href}`

        // http req header
        const requestHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + tokenId
        })

        if (create) {
            return this._httpClient.post(requestUrl, user, {
                headers: requestHeaders
            })
        } else {
            return this._httpClient.put(requestUrl, user, {
                headers: requestHeaders
            })
        }
    }

    deleteMember(id: number) {
        const tokenId = this._oidcSecurityService.getToken()

        const href = environment.sgl.users_url
        const requestUrl = `${href}/${id}`

        // http req header
        const requestHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + tokenId
        })

        return this._httpClient.delete(requestUrl, {
            headers: requestHeaders
        })
    }

    getCoordinators(): Observable<KeyValuePair<number, string>[]> {
        const tokenId = this._oidcSecurityService.getToken()

        const href = environment.sgl.users_url
        const requestUrl = `${href}/coordinators`

        // http req header
        const requestHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + tokenId
        })

        return this._httpClient.get<KeyValuePair<number, string>[]>(requestUrl, {
            headers: requestHeaders
        })
    }

    getFranchisees(activeAccountId: number): Observable<KeyValuePair<number, string>[]> {
        const tokenId = this._oidcSecurityService.getToken()

        const href = environment.sgl.users_url
        const requestUrl = `${href}/franchisees?activeAccountId=${activeAccountId}`

        // http req header
        const requestHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + tokenId
        })

        return this._httpClient.get<KeyValuePair<number, string>[]>(requestUrl, {
            headers: requestHeaders
        })
    }

    getUserProject(): Observable<UserProject> {
        const subject = new Subject<UserProject>()

        forkJoin({
            accountId: this.getActiveAccount(),
            projectId: this.getActiveProject()
        }).subscribe(({ accountId, projectId }) => {
            const tokenId = this._oidcSecurityService.getToken()
            const href = environment.sgl.users_url
            const requestUrl = `${href}/userproject?accountId=${accountId.key}&projectId=${projectId.key}`

            const requestHeaders = new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + tokenId
            })

            this._httpClient.get<UserProject>(requestUrl, {
                headers: requestHeaders
            }).subscribe(response => {
                this.supportsInventory = response.supportsInventory
                this.userProjectSource.next(response)
                subject.next(response)
                subject.complete()
            })
        })

        return subject.asObservable()
    }

    getProjects(): Observable<KeyValuePair<number, string>[]> {
        const subject = new Subject<KeyValuePair<number, string>[]>()
        this.getActiveAccount().subscribe(t => {
            const href = environment.sgl.users_url
            const requestUrl = `${href}/projects?accountId=${t.key}`

            this.getKeyValuePair(requestUrl).subscribe(projects => {
                subject.next(projects)
                subject.complete()
            })
        })

        return subject.asObservable()
    }

    getAccounts(): Observable<KeyValuePair<number, string>[]> {
        const href = environment.sgl.users_url
        const requestUrl = `${href}/accounts`

        return this.getKeyValuePair(requestUrl)
    }

    getActiveProject(): Observable<KeyValuePair<number, string>> {
        return new Observable<KeyValuePair<number, string>>(subscriber => {
            let projectsSubscription: Subscription
            projectsSubscription = this.getProjects().subscribe(projects => {
                const localProjectString = localStorage.getItem(ACTIVE_PROJECT_KEY)
                if (localProjectString == null || localProjectString === undefined || localProjectString.toLowerCase() === 'null') {
                    // take from existing projects
                    localStorage.setItem(ACTIVE_PROJECT_KEY, JSON.stringify(projects[0]))
                    subscriber.next(projects[0])
                    subscriber.complete()
                }
                else {
                    // determine if the local active project is part of this account
                    let activeProject = JSON.parse(localProjectString) as KeyValuePair<number, string>
                    if (projects.filter(p => p.key === activeProject.key).length === 0) {
                        localStorage.setItem(ACTIVE_PROJECT_KEY, JSON.stringify(projects[0]))
                        activeProject = projects[0]
                    }
                    subscriber.next(activeProject)
                    subscriber.complete()
                }
            })

            // cleanup when subscriber unsubscribes
            return () => {
                if (projectsSubscription) {
                    projectsSubscription.unsubscribe()
                }
            }
        })
    }

    getActiveAccount(): Observable<KeyValuePair<number, string>> {
        return new Observable<KeyValuePair<number, string>>(subscriber => {
            let accountsSubscription: Subscription

            const localAccountString = localStorage.getItem(ACTIVE_ACCOUNT_KEY)
            if (localAccountString && localAccountString !== 'null' && localAccountString !== 'undefined') {
                const activeAccount = JSON.parse(localAccountString) as KeyValuePair<number, string>
                subscriber.next(activeAccount)
                subscriber.complete()
            } else {
                accountsSubscription = this.getAccounts().subscribe(p => {
                    localStorage.setItem(ACTIVE_ACCOUNT_KEY, JSON.stringify(p[0]))
                    subscriber.next(p[0])
                    subscriber.complete()
                })
            }

            // cleanup when subscriber unsubscribes
            return () => {
                if (accountsSubscription) {
                    accountsSubscription.unsubscribe()
                }
            }
        })
    }

    private getKeyValuePair(requestUrl: string): Observable<KeyValuePair<number, string>[]> {
        const tokenId = this._oidcSecurityService.getToken()

        // http req header
        const requestHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + tokenId
        })

        return this._httpClient.get<KeyValuePair<number, string>[]>(requestUrl, {
            headers: requestHeaders
        })
    }

    private activeProjectIdSource: Subject<number> = new Subject<number>()
    activeProjectId$: Observable<number> = this.activeProjectIdSource.asObservable()

    changeActiveProject(project: KeyValuePair<number, string>) {
        localStorage.setItem(ACTIVE_PROJECT_KEY, JSON.stringify(project))
        this.activeProjectIdSource.next(project.key)
    }
    changeActiveAccount(account: KeyValuePair<number, string>, action: () => void) {
        localStorage.setItem(ACTIVE_ACCOUNT_KEY, JSON.stringify(account))

        action()
    }

    private userProjectSource: Subject<UserProject> = new Subject<UserProject>()
    userProject$: Observable<UserProject> = this.userProjectSource.asObservable()
}
