import { HttpClient, HttpHeaders } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { environment } from "@env/environment"
import { Observable } from "rxjs"
import { tap } from "rxjs/operators"
import { WebConfig } from "../models/configuration/web-config"

@Injectable({
    providedIn: 'root'
})
export class AuthConfigurationService {
    constructor(private _httpClient: HttpClient) { }

    loadAuthConfiguration(): Observable<WebConfig> {
        const requestUrl = `${this.getBaseUrl()}/api/v1/Configuration/Auth`

        return this._httpClient.get<WebConfig>(requestUrl, {
            headers: this.getRequestHeaders()
        }).pipe(
            tap(configuration => this.setEnvironment(configuration))
        )
    }

    private getBaseUrl() {
        if (window.location.origin.includes("localhost")) {
            return 'https://localhost:44369'
        } else if (window.location.origin.includes("uatsimpl.suddath.com")) {
            return window.location.origin.replace("uatsimpl.suddath.com", "uaue2sgljobswa01.azurewebsites.net");
        } else if (window.location.origin.includes("simpl.suddath.com")) {
            return window.location.origin.replace("simpl.suddath.com", "paue2sgljobswa01.azurewebsites.net");
        } else {
            return window.location.origin.replace("portalwb", "jobswa");
        }
    }

    private getRequestHeaders() {
        return new HttpHeaders({
            'Content-Type': 'application/json'
        })
    }

    private setEnvironment(configuration: WebConfig) {
        Object.assign(environment, configuration)
    }
}