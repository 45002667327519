import { Component, OnDestroy, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { Subscription } from 'rxjs'
import { Support } from '@shared/loader/models/support.model'
import { SuddathAuthenticationService } from '@suddath/authentication'

@Component( {
   selector   : 'app-loader-support',
   templateUrl: './loader-support.component.html',
   styleUrls  : ['./loader-support.component.scss']
} )

export class LoaderSupportComponent implements OnInit, OnDestroy {
   
   public id: number
   public subscription: Subscription
   public support: Support
   public loader: Support[]
   
   /**
    * constructor
    * @param _route
    * @param _router
    * @param _userService
    */
   constructor( private _route: ActivatedRoute,
                private _router: Router,
                private _userService: SuddathAuthenticationService ) {
      
      this.support = new Support()
      this.loader = [
         {
            id         : 100,
            type       : 'no-roles',
            title      : 'Suddath Portal Support',
            subTitle   : 'Your account does NOT have any role(s) setup, please contact your administrator.',
            solutionOne: 'Make sure you are using correct credentials.',
            solutionTwo: 'Contact us: (888)-799-5033'
         },
         {
            id         : 101,
            type       : 'not-auth',
            title      : 'Suddath Portal Support',
            subTitle   : 'Your are trying to access a secured module, please sign-in first.',
            solutionOne: 'You need to log-in.',
            solutionTwo: 'Contact us: (888)-799-5033'
         }
      ]
   }
   
   /**
    * ngOnInit - will handle to extract route param from the activated route
    */
   public ngOnInit() {
      this.subscription = this._route.params.subscribe( params => {
         this.id = +params['id'] // (+) converts string 'id' to a number
         // console.log( 'navigation parm: ' + this.id )
      } )
      
      const details = this.loader.filter( val => val.id === this.id )
      // console.log( details )

      this.support.id = details[0].id
      this.support.title = details[0].title
      this.support.subTitle = details[0].subTitle
      this.support.solutionOne = details[0].solutionOne
      this.support.solutionTwo = details[0].solutionTwo
   }
   
   /**
    * onClick: user will be log-in
    */
   public login() {
      this._userService.login()
   }
   
   /**
    * onClick: user will be log-out
    */
   public logout() {
      this._userService.logoff()
   }
   
   /**
    * ngOnDestroy: destroy current subscription
    */
   public ngOnDestroy(): void {
      this.subscription.unsubscribe()
   }
   
}
