
<div class="card pt-4 ml-4 mr-4 pl-4 pr-lg-4" style="margin-top: 150px">
   <!-- Jumbotron -->
   <div class="jumbotron text-center blue-grey lighten-5">

      <!-- Title -->
      <h2 class="card-title h2">Application Support</h2>

      <!-- Subtitle -->
      <p class="indigo-text my-4 font-weight-bold">
         Thank you for using the Suddath Portal. Unfortunately we encountered an error.
      </p>

      <!-- Grid row -->
      <div class="row d-flex justify-content-center">

         <!-- Grid column -->
         <div class="col-xl-7 pb-2 mt-5">

            <p class="card-text">Contact us:</p>
            <div class="list-group-flush">
               <!--               <div class="list-group-item">-->
               <!--                  <p class="mb-0">-->
               <!--                     <i class="far fa-user fa-2x mr-4 grey p-3 white-text rounded-circle " aria-hidden="true"></i>Try different credentials-->
               <!--                  </p>-->
               <!--               </div>-->
               <div class="list-group-item">
                  <p class="mb-0">
                     <i class="fas fa-phone fa-2x mr-4 mr-4 grey p-3 white-text rounded-circle" aria-hidden="true"></i>(888)-799-5033
                  </p>
               </div>
            </div>
         </div>
         <!-- Grid column -->

      </div>
      <!-- Grid row -->

      <hr class="my-4 pb-2">

      <button class="btn btn-primary btn-rounded" (click)="onClick()"><i class="fas fa-home mr-1"></i> home</button>

   </div>
   <!-- Jumbotron -->
</div>
