export class ShipmentsFakeDb {
  public static shipments = [
    {
      id: 0,
      name: 'Alford Johnston',
      transferee: 'Cunningham Mcconnell',
      counselor: 'Lynn Livingston',
      mode: 'AIR',
      reg_num: 200767,
      group_code: '5cd1e5b806be9be254cfa025',
      service: 'IMPORT',
      corp_acc: 'OVERSEAS AGENT BOOKING(128423)',
      date_entered: 'Thursday, April 25, 2019 8:49 AM',
      estimated_weight: '633.1',
      gross_weight: '394.3',
      handle: 'Connerton-Nebraska',
      origin_agent: 'ALL AMERICAN RELOCATION INC',
      origin_location: 'Croom, Maine',
      origin_pack_date: 'January 18, 2019',
      origin_load_date: 'January 8, 2019',
      dest_agent: 'PACKIMPEX MOVING LTD',
      dest_location: 'Fontanelle, Louisiana',
      dest_delivery_date: 'January 2, 2019',
      dest_storage_date: 'January 2, 2019',
      tracking_info: [
        {
          id: 0,
          date: 'Monday, May 6, 2019 2:53 PM',
          descr:
            'Adipisicing aliquip dolore nostrud ut Lorem commodo aliqua adipisicing fugiat minim sint proident ipsum.'
        }
      ],
      description:
        'February 10, 2019 cillum in sint consectetur Baker, Florida',
      categories: ['Air', 'Ocean'],
      tags: ['Export', 'Import'],
      featuredImageId: 0,
      images: [
        {
          id: 0,
          url: 'assets/images/ecommerce/lago-di-braies.jpg',
          type: 'image'
        },
        {
          id: 1,
          url: 'assets/images/ecommerce/never-stop-changing.jpg',
          type: 'image'
        }
      ],
      priceTaxExcl: '667.55',
      priceTaxIncl: '239.52',
      taxRate: 33,
      comparedPrice: '492.9',
      quantity: 98,
      sku: 'flight_takeoff',
      width: 6,
      height: 55,
      depth: 97,
      weight: '45.35',
      extraShippingFee: '7.67',
      active: true
    },
    {
      id: 1,
      name: 'Emma Chapman',
      transferee: 'Buchanan Lopez',
      counselor: 'Stark Jones',
      mode: 'SEA',
      reg_num: 268480,
      group_code: '5cd1e5b8e30dc3927f8e0cf9',
      service: 'IMPORT',
      corp_acc: 'OVERSEAS AGENT BOOKING(128423)',
      date_entered: 'Wednesday, February 6, 2019 11:44 AM',
      estimated_weight: 384,
      gross_weight: 884,
      handle: 'Witmer-Pennsylvania',
      origin_agent: 'ALL AMERICAN RELOCATION INC',
      origin_location: 'Homestead, North Carolina',
      origin_pack_date: 'January 15, 2019',
      origin_load_date: 'April 28, 2019',
      dest_agent: 'PACKIMPEX MOVING LTD',
      dest_location: 'Bladensburg, Minnesota',
      dest_delivery_date: 'February 28, 2019',
      dest_storage_date: 'April 28, 2019',
      tracking_info: [
        {
          id: 0,
          date: 'Wednesday, February 27, 2019 5:50 PM',
          descr:
            'Qui sunt proident et consectetur sunt in esse pariatur officia.'
        }
      ],
      description:
        'January 1, 2019 excepteur commodo nostrud quis Carrsville, American Samoa',
      categories: ['Air', 'Ocean'],
      tags: ['Export', 'Import'],
      featuredImageId: 1,
      images: [
        {
          id: 0,
          url: 'assets/images/ecommerce/reaching.jpg',
          type: 'image'
        },
        {
          id: 1,
          url: 'assets/images/ecommerce/first-snow.jpg',
          type: 'image'
        }
      ],
      priceTaxExcl: '482.95',
      priceTaxIncl: '70.4',
      taxRate: 13,
      comparedPrice: '759.88',
      quantity: 58,
      sku: 'directions_boat',
      width: 10,
      height: 51,
      depth: 73,
      weight: '28.04',
      extraShippingFee: '7.54',
      active: true
    },
    {
      id: 2,
      name: 'Young Cortez',
      transferee: 'Harrison Sears',
      counselor: 'Susan Brooks',
      mode: 'AIR',
      reg_num: 823228,
      group_code: '5cd1e5b88c88f6feb123d0fe',
      service: 'IMPORT',
      corp_acc: 'OVERSEAS AGENT BOOKING(128423)',
      date_entered: 'Sunday, January 6, 2019 8:19 AM',
      estimated_weight: '533.9',
      gross_weight: '857.7',
      handle: 'Laurelton-Palau',
      origin_agent: 'ALL AMERICAN RELOCATION INC',
      origin_location: 'Thermal, Wyoming',
      origin_pack_date: 'March 3, 2019',
      origin_load_date: 'April 25, 2019',
      dest_agent: 'PACKIMPEX MOVING LTD',
      dest_location: 'Greenock, Missouri',
      dest_delivery_date: 'February 21, 2019',
      dest_storage_date: 'March 8, 2019',
      tracking_info: [
        {
          id: 0,
          date: 'Saturday, May 4, 2019 8:24 PM',
          descr: 'Non pariatur ea nulla irure do dolore quis Lorem culpa.'
        }
      ],
      description:
        'February 10, 2019 sunt mollit consectetur velit Breinigsville, Washington',
      categories: ['Air', 'Ocean'],
      tags: ['Export', 'Import'],
      featuredImageId: 2,
      images: [
        {
          id: 0,
          url: 'assets/images/ecommerce/braies-lake.jpg',
          type: 'image'
        },
        {
          id: 1,
          url: 'assets/images/ecommerce/lago-di-sorapis.jpg',
          type: 'image'
        }
      ],
      priceTaxExcl: '993.57',
      priceTaxIncl: '429.61',
      taxRate: 11,
      comparedPrice: '634.05',
      quantity: 11,
      sku: 'flight_takeoff',
      width: 36,
      height: 12,
      depth: 40,
      weight: '71.92',
      extraShippingFee: '1.34',
      active: false
    },
    {
      id: 3,
      name: 'Walker Clements',
      transferee: 'Dyer Waters',
      counselor: 'Morton Santos',
      mode: 'AIR',
      reg_num: 612533,
      group_code: '5cd1e5b819467174ba947911',
      service: 'IMPORT',
      corp_acc: 'OVERSEAS AGENT BOOKING(128423)',
      date_entered: 'Friday, March 15, 2019 11:28 PM',
      estimated_weight: 724,
      gross_weight: '932.5',
      handle: 'Goldfield-Ohio',
      origin_agent: 'ALL AMERICAN RELOCATION INC',
      origin_location: 'Shelby, District Of Columbia',
      origin_pack_date: 'February 10, 2019',
      origin_load_date: 'January 13, 2019',
      dest_agent: 'PACKIMPEX MOVING LTD',
      dest_location: 'Cumminsville, New Hampshire',
      dest_delivery_date: 'January 10, 2019',
      dest_storage_date: 'March 8, 2019',
      tracking_info: [
        {
          id: 0,
          date: 'Sunday, April 21, 2019 8:33 PM',
          descr: 'Irure pariatur aute aliqua irure qui.'
        }
      ],
      description:
        'February 2, 2019 quis irure nostrud aliquip Carrizo, Hawaii',
      categories: ['Air', 'Ocean'],
      tags: ['Export', 'Import'],
      featuredImageId: 3,
      images: [
        {
          id: 0,
          url: 'assets/images/ecommerce/braies-lake.jpg',
          type: 'image'
        },
        {
          id: 1,
          url: 'assets/images/ecommerce/lago-di-sorapis.jpg',
          type: 'image'
        }
      ],
      priceTaxExcl: '258.96',
      priceTaxIncl: '198.75',
      taxRate: 7,
      comparedPrice: '760.66',
      quantity: 52,
      sku: 'flight_takeoff',
      width: 44,
      height: 5,
      depth: 69,
      weight: '87.71',
      extraShippingFee: '4.82',
      active: false
    },
    {
      id: 4,
      name: 'Curry Holcomb',
      transferee: 'Burch Wagner',
      counselor: 'Mcpherson Carroll',
      mode: 'SEA',
      reg_num: 732344,
      group_code: '5cd1e5b8709fa8a48cff8264',
      service: 'IMPORT',
      corp_acc: 'OVERSEAS AGENT BOOKING(128423)',
      date_entered: 'Sunday, March 3, 2019 12:21 PM',
      estimated_weight: '881.3',
      gross_weight: '785.7',
      handle: 'Vale-Arizona',
      origin_agent: 'ALL AMERICAN RELOCATION INC',
      origin_location: 'Baden, Massachusetts',
      origin_pack_date: 'January 2, 2019',
      origin_load_date: 'February 18, 2019',
      dest_agent: 'PACKIMPEX MOVING LTD',
      dest_location: 'Epworth, Colorado',
      dest_delivery_date: 'March 2, 2019',
      dest_storage_date: 'April 25, 2019',
      tracking_info: [
        {
          id: 0,
          date: 'Sunday, January 20, 2019 7:34 PM',
          descr:
            'Non esse mollit cillum non velit laborum minim fugiat nisi eiusmod eu nostrud.'
        }
      ],
      description:
        'February 8, 2019 ullamco minim adipisicing ex Bawcomville, Alabama',
      categories: ['Air', 'Ocean'],
      tags: ['Export', 'Import'],
      featuredImageId: 4,
      images: [
        {
          id: 0,
          url: 'assets/images/ecommerce/lago-di-sorapis.jpg',
          type: 'image'
        },
        {
          id: 1,
          url: 'assets/images/ecommerce/a-walk-amongst-friends.jpg',
          type: 'image'
        }
      ],
      priceTaxExcl: '412.08',
      priceTaxIncl: '511.91',
      taxRate: 28,
      comparedPrice: '621.41',
      quantity: 72,
      sku: 'directions_boat',
      width: 28,
      height: 99,
      depth: 17,
      weight: '96.08',
      extraShippingFee: '4.64',
      active: false
    },
    {
      id: 5,
      name: 'Noemi Curry',
      transferee: 'Bethany Colon',
      counselor: 'Wendy Hinton',
      mode: 'GROUND',
      reg_num: 342648,
      group_code: '5cd1e5b8a179a541d3cd9209',
      service: 'IMPORT',
      corp_acc: 'OVERSEAS AGENT BOOKING(128423)',
      date_entered: 'Thursday, February 28, 2019 10:33 AM',
      estimated_weight: 510,
      gross_weight: '595.4',
      handle: 'Abrams-Wisconsin',
      origin_agent: 'ALL AMERICAN RELOCATION INC',
      origin_location: 'Sussex, Guam',
      origin_pack_date: 'March 19, 2019',
      origin_load_date: 'April 27, 2019',
      dest_agent: 'PACKIMPEX MOVING LTD',
      dest_location: 'Nipinnawasee, Tennessee',
      dest_delivery_date: 'February 21, 2019',
      dest_storage_date: 'March 22, 2019',
      tracking_info: [
        {
          id: 0,
          date: 'Sunday, April 21, 2019 6:01 PM',
          descr: 'Occaecat dolor laborum non eiusmod elit eu laboris dolor.'
        }
      ],
      description:
        'February 11, 2019 excepteur incididunt elit nostrud Lund, Kentucky',
      categories: ['Air', 'Ocean'],
      tags: ['Export', 'Import'],
      featuredImageId: 5,
      images: [
        {
          id: 0,
          url: 'assets/images/ecommerce/yosemite.jpg',
          type: 'image'
        },
        {
          id: 1,
          url: 'assets/images/ecommerce/lago-di-sorapis.jpg',
          type: 'image'
        }
      ],
      priceTaxExcl: '122.45',
      priceTaxIncl: '106.25',
      taxRate: 2,
      comparedPrice: '319.96',
      quantity: 3,
      sku: 'local_shipping',
      width: 77,
      height: 58,
      depth: 56,
      weight: '40.22',
      extraShippingFee: '4.33',
      active: true
    },
    {
      id: 6,
      name: 'Jessie Burns',
      transferee: 'Amie Oconnor',
      counselor: 'Katrina Roberts',
      mode: 'GROUND',
      reg_num: 306320,
      group_code: '5cd1e5b824eadb17627ac6e7',
      service: 'IMPORT',
      corp_acc: 'OVERSEAS AGENT BOOKING(128423)',
      date_entered: 'Tuesday, February 26, 2019 10:29 AM',
      estimated_weight: '510.6',
      gross_weight: '825.1',
      handle: 'Bartley-Puerto Rico',
      origin_agent: 'ALL AMERICAN RELOCATION INC',
      origin_location: 'Adelino, Michigan',
      origin_pack_date: 'January 13, 2019',
      origin_load_date: 'March 6, 2019',
      dest_agent: 'PACKIMPEX MOVING LTD',
      dest_location: 'Siglerville, Kansas',
      dest_delivery_date: 'February 12, 2019',
      dest_storage_date: 'April 12, 2019',
      tracking_info: [
        {
          id: 0,
          date: 'Monday, January 7, 2019 12:11 AM',
          descr: 'Velit nisi et amet ipsum laboris.'
        }
      ],
      description:
        'April 21, 2019 veniam exercitation aliquip dolor Brutus, Montana',
      categories: ['Air', 'Ocean'],
      tags: ['Export', 'Import'],
      featuredImageId: 6,
      images: [
        {
          id: 0,
          url: 'assets/images/ecommerce/a-walk-amongst-friends.jpg',
          type: 'image'
        },
        {
          id: 1,
          url: 'assets/images/ecommerce/lago-di-sorapis.jpg',
          type: 'image'
        }
      ],
      priceTaxExcl: '387.49',
      priceTaxIncl: '198.41',
      taxRate: 10,
      comparedPrice: '670.34',
      quantity: 9,
      sku: 'local_shipping',
      width: 61,
      height: 32,
      depth: 39,
      weight: '64.34',
      extraShippingFee: '2.73',
      active: true
    },
    {
      id: 7,
      name: 'Mavis Valentine',
      transferee: 'Liz Paul',
      counselor: 'Sandra Bruce',
      mode: 'GROUND',
      reg_num: 691159,
      group_code: '5cd1e5b82c99e13ec2f2b13f',
      service: 'IMPORT',
      corp_acc: 'OVERSEAS AGENT BOOKING(128423)',
      date_entered: 'Sunday, April 14, 2019 4:26 AM',
      estimated_weight: '139.6',
      gross_weight: '888.4',
      handle: 'Boomer-Vermont',
      origin_agent: 'ALL AMERICAN RELOCATION INC',
      origin_location: 'Hamilton, Iowa',
      origin_pack_date: 'April 1, 2019',
      origin_load_date: 'April 7, 2019',
      dest_agent: 'PACKIMPEX MOVING LTD',
      dest_location: 'Cazadero, New Jersey',
      dest_delivery_date: 'April 21, 2019',
      dest_storage_date: 'March 1, 2019',
      tracking_info: [
        {
          id: 0,
          date: 'Sunday, March 3, 2019 7:56 AM',
          descr:
            'Duis eiusmod cillum aliqua dolore ad cupidatat amet anim mollit sint veniam duis ullamco est.'
        }
      ],
      description:
        'March 31, 2019 ad aliquip esse qui Bodega, Federated States Of Micronesia',
      categories: ['Air', 'Ocean'],
      tags: ['Export', 'Import'],
      featuredImageId: 7,
      images: [
        {
          id: 0,
          url: 'assets/images/ecommerce/never-stop-changing.jpg',
          type: 'image'
        },
        {
          id: 1,
          url: 'assets/images/ecommerce/morain-lake.jpg',
          type: 'image'
        }
      ],
      priceTaxExcl: '159.31',
      priceTaxIncl: '806.72',
      taxRate: 6,
      comparedPrice: '750.4',
      quantity: 16,
      sku: 'local_shipping',
      width: 65,
      height: 95,
      depth: 34,
      weight: '79.12',
      extraShippingFee: '1.58',
      active: true
    },
    {
      id: 8,
      name: 'Acosta Sexton',
      transferee: 'Farrell Jacobs',
      counselor: 'Savannah Carpenter',
      mode: 'SEA',
      reg_num: 111109,
      group_code: '5cd1e5b82a49536a9f07184b',
      service: 'IMPORT',
      corp_acc: 'OVERSEAS AGENT BOOKING(128423)',
      date_entered: 'Monday, April 8, 2019 10:14 PM',
      estimated_weight: '196.1',
      gross_weight: 506,
      handle: 'Fairacres-South Carolina',
      origin_agent: 'ALL AMERICAN RELOCATION INC',
      origin_location: 'Westboro, Maryland',
      origin_pack_date: 'April 27, 2019',
      origin_load_date: 'April 18, 2019',
      dest_agent: 'PACKIMPEX MOVING LTD',
      dest_location: 'Caln, Marshall Islands',
      dest_delivery_date: 'January 26, 2019',
      dest_storage_date: 'February 26, 2019',
      tracking_info: [
        {
          id: 0,
          date: 'Thursday, March 14, 2019 5:36 PM',
          descr:
            'Nostrud dolor incididunt adipisicing enim commodo elit eiusmod voluptate velit in.'
        }
      ],
      description:
        'March 27, 2019 dolore sunt voluptate adipisicing Cochranville, Georgia',
      categories: ['Air', 'Ocean'],
      tags: ['Export', 'Import'],
      featuredImageId: 8,
      images: [
        {
          id: 0,
          url: 'assets/images/ecommerce/lago-di-braies.jpg',
          type: 'image'
        },
        {
          id: 1,
          url: 'assets/images/ecommerce/a-walk-amongst-friends.jpg',
          type: 'image'
        }
      ],
      priceTaxExcl: '359.04',
      priceTaxIncl: '239.32',
      taxRate: 21,
      comparedPrice: '349.16',
      quantity: 36,
      sku: 'directions_boat',
      width: 27,
      height: 26,
      depth: 72,
      weight: '91.15',
      extraShippingFee: '1.87',
      active: true
    },
    {
      id: 9,
      name: 'Luella Chaney',
      transferee: 'Rachelle Harper',
      counselor: 'Ray Rasmussen',
      mode: 'GROUND',
      reg_num: 842315,
      group_code: '5cd1e5b8e5d66cc26d2c41f5',
      service: 'EXPORT',
      corp_acc: 'OVERSEAS AGENT BOOKING(128423)',
      date_entered: 'Thursday, March 21, 2019 4:18 PM',
      estimated_weight: '266.2',
      gross_weight: '553.3',
      handle: 'Geyserville-Idaho',
      origin_agent: 'ALL AMERICAN RELOCATION INC',
      origin_location: 'Dellview, Delaware',
      origin_pack_date: 'February 23, 2019',
      origin_load_date: 'January 20, 2019',
      dest_agent: 'PACKIMPEX MOVING LTD',
      dest_location: 'Barclay, North Dakota',
      dest_delivery_date: 'April 4, 2019',
      dest_storage_date: 'January 23, 2019',
      tracking_info: [
        {
          id: 0,
          date: 'Wednesday, January 16, 2019 4:25 PM',
          descr:
            'Non irure aute deserunt ut duis labore labore velit ullamco culpa dolor consectetur.'
        }
      ],
      description: 'January 12, 2019 dolor magna laboris anim Tilden, Illinois',
      categories: ['Air', 'Ocean'],
      tags: ['Export', 'Import'],
      featuredImageId: 9,
      images: [
        {
          id: 0,
          url: 'assets/images/ecommerce/lago-di-sorapis.jpg',
          type: 'image'
        },
        {
          id: 1,
          url: 'assets/images/ecommerce/fall-glow.jpg',
          type: 'image'
        }
      ],
      priceTaxExcl: '868.78',
      priceTaxIncl: '621.99',
      taxRate: 32,
      comparedPrice: '656.63',
      quantity: 82,
      sku: 'local_shipping',
      width: 78,
      height: 52,
      depth: 95,
      weight: '22.97',
      extraShippingFee: '5.55',
      active: false
    },
    {
      id: 10,
      name: 'Elsa Hays',
      transferee: 'Sharpe Perry',
      counselor: 'Ramos Bennett',
      mode: 'GROUND',
      reg_num: 210697,
      group_code: '5cd1e5b8dfc9a5f1245758d2',
      service: 'EXPORT',
      corp_acc: 'OVERSEAS AGENT BOOKING(128423)',
      date_entered: 'Saturday, March 2, 2019 10:27 PM',
      estimated_weight: '528.2',
      gross_weight: '541.5',
      handle: 'Websterville-Alaska',
      origin_agent: 'ALL AMERICAN RELOCATION INC',
      origin_location: 'Fairforest, Nevada',
      origin_pack_date: 'February 4, 2019',
      origin_load_date: 'April 21, 2019',
      dest_agent: 'PACKIMPEX MOVING LTD',
      dest_location: 'Gilmore, West Virginia',
      dest_delivery_date: 'March 27, 2019',
      dest_storage_date: 'March 31, 2019',
      tracking_info: [
        {
          id: 0,
          date: 'Monday, April 15, 2019 2:33 AM',
          descr:
            'Ipsum consequat enim nisi consectetur consequat Lorem non do deserunt et nulla est.'
        }
      ],
      description:
        'March 7, 2019 eiusmod pariatur esse consectetur Datil, Indiana',
      categories: ['Air', 'Ocean'],
      tags: ['Export', 'Import'],
      featuredImageId: 10,
      images: [
        {
          id: 0,
          url: 'assets/images/ecommerce/yosemite.jpg',
          type: 'image'
        },
        {
          id: 1,
          url: 'assets/images/ecommerce/lago-di-braies.jpg',
          type: 'image'
        }
      ],
      priceTaxExcl: '195.56',
      priceTaxIncl: '392.85',
      taxRate: 28,
      comparedPrice: '88.8',
      quantity: 1,
      sku: 'local_shipping',
      width: 52,
      height: 57,
      depth: 85,
      weight: '91.45',
      extraShippingFee: '2.62',
      active: true
    },
    {
      id: 11,
      name: 'Rocha Brock',
      transferee: 'Lambert Mooney',
      counselor: 'Kerry Barton',
      mode: 'AIR',
      reg_num: 117874,
      group_code: '5cd1e5b8fd2ece827587edca',
      service: 'IMPORT',
      corp_acc: 'OVERSEAS AGENT BOOKING(128423)',
      date_entered: 'Sunday, April 21, 2019 4:42 PM',
      estimated_weight: 114,
      gross_weight: '393.5',
      handle: 'Blairstown-Oregon',
      origin_agent: 'ALL AMERICAN RELOCATION INC',
      origin_location: 'Vincent, Rhode Island',
      origin_pack_date: 'March 2, 2019',
      origin_load_date: 'February 18, 2019',
      dest_agent: 'PACKIMPEX MOVING LTD',
      dest_location: 'Gardiner, California',
      dest_delivery_date: 'April 1, 2019',
      dest_storage_date: 'January 26, 2019',
      tracking_info: [
        {
          id: 0,
          date: 'Sunday, January 6, 2019 7:32 PM',
          descr: 'Consequat aliquip aliqua ex cupidatat.'
        }
      ],
      description: 'May 7, 2019 qui irure fugiat nisi Hoagland, New York',
      categories: ['Air', 'Ocean'],
      tags: ['Export', 'Import'],
      featuredImageId: 11,
      images: [
        {
          id: 0,
          url: 'assets/images/ecommerce/never-stop-changing.jpg',
          type: 'image'
        },
        {
          id: 1,
          url: 'assets/images/ecommerce/first-snow.jpg',
          type: 'image'
        }
      ],
      priceTaxExcl: '635.59',
      priceTaxIncl: '529.02',
      taxRate: 17,
      comparedPrice: '665.41',
      quantity: 95,
      sku: 'flight_takeoff',
      width: 48,
      height: 86,
      depth: 73,
      weight: '29.59',
      extraShippingFee: '4.56',
      active: true
    },
    {
      id: 12,
      name: 'Richmond Pena',
      transferee: 'Isabelle Mclaughlin',
      counselor: 'Elnora Huff',
      mode: 'GROUND',
      reg_num: 264893,
      group_code: '5cd1e5b8675fbd00bb6d53d6',
      service: 'IMPORT',
      corp_acc: 'OVERSEAS AGENT BOOKING(128423)',
      date_entered: 'Monday, February 25, 2019 7:46 PM',
      estimated_weight: '314.6',
      gross_weight: '798.6',
      handle: 'Chamberino-Connecticut',
      origin_agent: 'ALL AMERICAN RELOCATION INC',
      origin_location: 'Felt, Arkansas',
      origin_pack_date: 'January 3, 2019',
      origin_load_date: 'April 17, 2019',
      dest_agent: 'PACKIMPEX MOVING LTD',
      dest_location: 'Draper, South Dakota',
      dest_delivery_date: 'January 3, 2019',
      dest_storage_date: 'May 3, 2019',
      tracking_info: [
        {
          id: 0,
          date: 'Friday, April 12, 2019 5:58 AM',
          descr: 'Minim nulla anim et ullamco non magna elit.'
        }
      ],
      description:
        'March 3, 2019 in dolore ipsum in Onton, Northern Mariana Islands',
      categories: ['Air', 'Ocean'],
      tags: ['Export', 'Import'],
      featuredImageId: 12,
      images: [
        {
          id: 0,
          url: 'assets/images/ecommerce/yosemite.jpg',
          type: 'image'
        },
        {
          id: 1,
          url: 'assets/images/ecommerce/fall-glow.jpg',
          type: 'image'
        }
      ],
      priceTaxExcl: '33.38',
      priceTaxIncl: '474.5',
      taxRate: 9,
      comparedPrice: '362.31',
      quantity: 80,
      sku: 'local_shipping',
      width: 39,
      height: 91,
      depth: 98,
      weight: '67.22',
      extraShippingFee: '6.3',
      active: false
    },
    {
      id: 13,
      name: 'Charity Goff',
      transferee: 'Jolene Estrada',
      counselor: 'Dianna Oneill',
      mode: 'AIR',
      reg_num: 499668,
      group_code: '5cd1e5b8388bf0137493896a',
      service: 'IMPORT',
      corp_acc: 'OVERSEAS AGENT BOOKING(128423)',
      date_entered: 'Saturday, March 9, 2019 6:18 PM',
      estimated_weight: '202.4',
      gross_weight: '439.6',
      handle: 'Darlington-Virgin Islands',
      origin_agent: 'ALL AMERICAN RELOCATION INC',
      origin_location: 'Ripley, Utah',
      origin_pack_date: 'January 14, 2019',
      origin_load_date: 'March 20, 2019',
      dest_agent: 'PACKIMPEX MOVING LTD',
      dest_location: 'Neahkahnie, Oklahoma',
      dest_delivery_date: 'April 22, 2019',
      dest_storage_date: 'February 13, 2019',
      tracking_info: [
        {
          id: 0,
          date: 'Sunday, March 24, 2019 7:11 PM',
          descr: 'Nulla incididunt consequat et ex cillum.'
        }
      ],
      description: 'April 16, 2019 sit sint cillum laborum Yettem, Virginia',
      categories: ['Air', 'Ocean'],
      tags: ['Export', 'Import'],
      featuredImageId: 13,
      images: [
        {
          id: 0,
          url: 'assets/images/ecommerce/a-walk-amongst-friends.jpg',
          type: 'image'
        },
        {
          id: 1,
          url: 'assets/images/ecommerce/morain-lake.jpg',
          type: 'image'
        }
      ],
      priceTaxExcl: '169.14',
      priceTaxIncl: '496.2',
      taxRate: 31,
      comparedPrice: '982.78',
      quantity: 36,
      sku: 'flight_takeoff',
      width: 87,
      height: 80,
      depth: 38,
      weight: '92.59',
      extraShippingFee: '2.04',
      active: true
    },
    {
      id: 14,
      name: 'Meyers Stanley',
      transferee: 'Martinez Lee',
      counselor: 'Claudine Ramirez',
      mode: 'AIR',
      reg_num: 107592,
      group_code: '5cd1e5b85c55ddbe6daa6675',
      service: 'IMPORT',
      corp_acc: 'OVERSEAS AGENT BOOKING(128423)',
      date_entered: 'Saturday, April 13, 2019 7:32 AM',
      estimated_weight: '792.7',
      gross_weight: '756.9',
      handle: 'Balm-New Mexico',
      origin_agent: 'ALL AMERICAN RELOCATION INC',
      origin_location: 'Beaverdale, Mississippi',
      origin_pack_date: 'April 19, 2019',
      origin_load_date: 'May 5, 2019',
      dest_agent: 'PACKIMPEX MOVING LTD',
      dest_location: 'Winesburg, Nebraska',
      dest_delivery_date: 'March 16, 2019',
      dest_storage_date: 'March 29, 2019',
      tracking_info: [
        {
          id: 0,
          date: 'Friday, February 15, 2019 11:48 PM',
          descr:
            'Velit sunt non duis aliquip labore cillum sit nisi veniam quis excepteur irure.'
        }
      ],
      description: 'April 20, 2019 nisi dolor et fugiat Neibert, Maine',
      categories: ['Air', 'Ocean'],
      tags: ['Export', 'Import'],
      featuredImageId: 14,
      images: [
        {
          id: 0,
          url: 'assets/images/ecommerce/lago-di-braies.jpg',
          type: 'image'
        },
        {
          id: 1,
          url: 'assets/images/ecommerce/fall-glow.jpg',
          type: 'image'
        }
      ],
      priceTaxExcl: '36.76',
      priceTaxIncl: '776.31',
      taxRate: 14,
      comparedPrice: '173.56',
      quantity: 45,
      sku: 'flight_takeoff',
      width: 13,
      height: 30,
      depth: 65,
      weight: '95.12',
      extraShippingFee: '3.65',
      active: true
    },
    {
      id: 15,
      name: 'Sosa Meyer',
      transferee: 'Alvarado Sims',
      counselor: 'Quinn Levine',
      mode: 'SEA',
      reg_num: 885585,
      group_code: '5cd1e5b810856cb12dc4e7af',
      service: 'EXPORT',
      corp_acc: 'OVERSEAS AGENT BOOKING(128423)',
      date_entered: 'Saturday, February 23, 2019 9:05 PM',
      estimated_weight: '231.2',
      gross_weight: '79.8',
      handle: 'Clay-Louisiana',
      origin_agent: 'ALL AMERICAN RELOCATION INC',
      origin_location: 'Elrama, Florida',
      origin_pack_date: 'March 29, 2019',
      origin_load_date: 'February 28, 2019',
      dest_agent: 'PACKIMPEX MOVING LTD',
      dest_location: 'Cliff, Pennsylvania',
      dest_delivery_date: 'February 10, 2019',
      dest_storage_date: 'April 19, 2019',
      tracking_info: [
        {
          id: 0,
          date: 'Wednesday, April 17, 2019 7:39 AM',
          descr: 'Fugiat eu ut elit velit.'
        }
      ],
      description:
        'February 18, 2019 fugiat ex nostrud quis Freeburn, North Carolina',
      categories: ['Air', 'Ocean'],
      tags: ['Export', 'Import'],
      featuredImageId: 15,
      images: [
        {
          id: 0,
          url: 'assets/images/ecommerce/reaching.jpg',
          type: 'image'
        },
        {
          id: 1,
          url: 'assets/images/ecommerce/a-walk-amongst-friends.jpg',
          type: 'image'
        }
      ],
      priceTaxExcl: '672.44',
      priceTaxIncl: '762.84',
      taxRate: 3,
      comparedPrice: '852.28',
      quantity: 53,
      sku: 'directions_boat',
      width: 72,
      height: 24,
      depth: 99,
      weight: '71.14',
      extraShippingFee: '8.79',
      active: false
    },
    {
      id: 16,
      name: 'Sexton Palmer',
      transferee: 'Cabrera Collier',
      counselor: 'Shepard Callahan',
      mode: 'SEA',
      reg_num: 331287,
      group_code: '5cd1e5b8877609e7eb45c4a1',
      service: 'EXPORT',
      corp_acc: 'OVERSEAS AGENT BOOKING(128423)',
      date_entered: 'Tuesday, January 15, 2019 4:58 PM',
      estimated_weight: 451,
      gross_weight: '455.6',
      handle: 'Rivereno-Minnesota',
      origin_agent: 'ALL AMERICAN RELOCATION INC',
      origin_location: 'Kipp, American Samoa',
      origin_pack_date: 'May 5, 2019',
      origin_load_date: 'January 7, 2019',
      dest_agent: 'PACKIMPEX MOVING LTD',
      dest_location: 'Bison, Palau',
      dest_delivery_date: 'January 3, 2019',
      dest_storage_date: 'March 4, 2019',
      tracking_info: [
        {
          id: 0,
          date: 'Saturday, March 2, 2019 5:30 AM',
          descr:
            'Aliquip ipsum labore quis magna anim aliqua occaecat anim irure.'
        }
      ],
      description:
        'February 1, 2019 aliquip commodo dolore dolor Morgandale, Wyoming',
      categories: ['Air', 'Ocean'],
      tags: ['Export', 'Import'],
      featuredImageId: 16,
      images: [
        {
          id: 0,
          url: 'assets/images/ecommerce/braies-lake.jpg',
          type: 'image'
        },
        {
          id: 1,
          url: 'assets/images/ecommerce/lago-di-braies.jpg',
          type: 'image'
        }
      ],
      priceTaxExcl: '774.47',
      priceTaxIncl: '810.75',
      taxRate: 15,
      comparedPrice: '89.25',
      quantity: 2,
      sku: 'directions_boat',
      width: 96,
      height: 15,
      depth: 35,
      weight: '79.73',
      extraShippingFee: '3.34',
      active: true
    },
    {
      id: 17,
      name: 'Anderson Blake',
      transferee: 'Lourdes Gates',
      counselor: 'Keith Whitehead',
      mode: 'AIR',
      reg_num: 992047,
      group_code: '5cd1e5b84bf6361edde5f82d',
      service: 'EXPORT',
      corp_acc: 'OVERSEAS AGENT BOOKING(128423)',
      date_entered: 'Tuesday, February 12, 2019 9:42 AM',
      estimated_weight: '296.6',
      gross_weight: '218.2',
      handle: 'Corriganville-Missouri',
      origin_agent: 'ALL AMERICAN RELOCATION INC',
      origin_location: 'Trail, Washington',
      origin_pack_date: 'February 25, 2019',
      origin_load_date: 'April 19, 2019',
      dest_agent: 'PACKIMPEX MOVING LTD',
      dest_location: 'Enetai, Ohio',
      dest_delivery_date: 'March 8, 2019',
      dest_storage_date: 'January 8, 2019',
      tracking_info: [
        {
          id: 0,
          date: 'Friday, March 8, 2019 12:18 PM',
          descr: 'Culpa aute magna dolor sit minim aliquip do.'
        }
      ],
      description:
        'March 24, 2019 commodo cupidatat proident consectetur Chesterfield, District Of Columbia',
      categories: ['Air', 'Ocean'],
      tags: ['Export', 'Import'],
      featuredImageId: 17,
      images: [
        {
          id: 0,
          url: 'assets/images/ecommerce/braies-lake.jpg',
          type: 'image'
        },
        {
          id: 1,
          url: 'assets/images/ecommerce/braies-lake.jpg',
          type: 'image'
        }
      ],
      priceTaxExcl: '696.56',
      priceTaxIncl: '539.54',
      taxRate: 29,
      comparedPrice: '250.79',
      quantity: 33,
      sku: 'flight_takeoff',
      width: 3,
      height: 84,
      depth: 44,
      weight: '90.67',
      extraShippingFee: '0.8',
      active: false
    },
    {
      id: 18,
      name: 'Serena Frost',
      transferee: 'Norton Byrd',
      counselor: 'Deana Hopkins',
      mode: 'SEA',
      reg_num: 590060,
      group_code: '5cd1e5b806093f79eb96b0a4',
      service: 'EXPORT',
      corp_acc: 'OVERSEAS AGENT BOOKING(128423)',
      date_entered: 'Monday, February 11, 2019 12:59 AM',
      estimated_weight: '644.8',
      gross_weight: '257.9',
      handle: 'Magnolia-New Hampshire',
      origin_agent: 'ALL AMERICAN RELOCATION INC',
      origin_location: 'Weedville, Hawaii',
      origin_pack_date: 'January 14, 2019',
      origin_load_date: 'February 9, 2019',
      dest_agent: 'PACKIMPEX MOVING LTD',
      dest_location: 'Alafaya, Arizona',
      dest_delivery_date: 'March 7, 2019',
      dest_storage_date: 'January 16, 2019',
      tracking_info: [
        {
          id: 0,
          date: 'Wednesday, February 20, 2019 8:50 AM',
          descr: 'Pariatur aliqua excepteur sit sit.'
        }
      ],
      description:
        'March 20, 2019 ea ipsum occaecat quis Newcastle, Massachusetts',
      categories: ['Air', 'Ocean'],
      tags: ['Export', 'Import'],
      featuredImageId: 18,
      images: [
        {
          id: 0,
          url: 'assets/images/ecommerce/morain-lake.jpg',
          type: 'image'
        },
        {
          id: 1,
          url: 'assets/images/ecommerce/reaching.jpg',
          type: 'image'
        }
      ],
      priceTaxExcl: '629.28',
      priceTaxIncl: '438.73',
      taxRate: 34,
      comparedPrice: '579.73',
      quantity: 18,
      sku: 'directions_boat',
      width: 27,
      height: 84,
      depth: 66,
      weight: '36.79',
      extraShippingFee: '3.98',
      active: false
    },
    {
      id: 19,
      name: 'Hardy Collins',
      transferee: 'Weeks Herman',
      counselor: 'David Delaney',
      mode: 'GROUND',
      reg_num: 618305,
      group_code: '5cd1e5b8fdae1d8b76de7553',
      service: 'IMPORT',
      corp_acc: 'OVERSEAS AGENT BOOKING(128423)',
      date_entered: 'Wednesday, January 23, 2019 8:36 AM',
      estimated_weight: 368,
      gross_weight: '275.2',
      handle: 'Roderfield-Colorado',
      origin_agent: 'ALL AMERICAN RELOCATION INC',
      origin_location: 'Ahwahnee, Alabama',
      origin_pack_date: 'February 24, 2019',
      origin_load_date: 'March 6, 2019',
      dest_agent: 'PACKIMPEX MOVING LTD',
      dest_location: 'Brookfield, Wisconsin',
      dest_delivery_date: 'January 26, 2019',
      dest_storage_date: 'March 24, 2019',
      tracking_info: [
        {
          id: 0,
          date: 'Wednesday, January 16, 2019 10:38 PM',
          descr: 'Id Lorem mollit Lorem cupidatat.'
        }
      ],
      description:
        'February 21, 2019 reprehenderit eiusmod ex officia Driftwood, Guam',
      categories: ['Air', 'Ocean'],
      tags: ['Export', 'Import'],
      featuredImageId: 19,
      images: [
        {
          id: 0,
          url: 'assets/images/ecommerce/lago-di-braies.jpg',
          type: 'image'
        },
        {
          id: 1,
          url: 'assets/images/ecommerce/first-snow.jpg',
          type: 'image'
        }
      ],
      priceTaxExcl: '679.99',
      priceTaxIncl: '310.16',
      taxRate: 4,
      comparedPrice: '270.94',
      quantity: 74,
      sku: 'local_shipping',
      width: 96,
      height: 52,
      depth: 31,
      weight: '46.31',
      extraShippingFee: '1.86',
      active: false
    },
    {
      id: 20,
      name: 'Casey Chan',
      transferee: 'Roseann George',
      counselor: 'Ross Mcpherson',
      mode: 'SEA',
      reg_num: 404113,
      group_code: '5cd1e5b884a36303aa6babab',
      service: 'IMPORT',
      corp_acc: 'OVERSEAS AGENT BOOKING(128423)',
      date_entered: 'Sunday, April 14, 2019 2:27 PM',
      estimated_weight: '881.6',
      gross_weight: '439.8',
      handle: 'Wescosville-Tennessee',
      origin_agent: 'ALL AMERICAN RELOCATION INC',
      origin_location: 'Ezel, Kentucky',
      origin_pack_date: 'March 2, 2019',
      origin_load_date: 'March 29, 2019',
      dest_agent: 'PACKIMPEX MOVING LTD',
      dest_location: 'Eastvale, Puerto Rico',
      dest_delivery_date: 'January 23, 2019',
      dest_storage_date: 'January 2, 2019',
      tracking_info: [
        {
          id: 0,
          date: 'Sunday, January 6, 2019 8:04 AM',
          descr:
            'Cillum culpa Lorem mollit commodo sit laborum sit quis esse ut ullamco.'
        }
      ],
      description: 'April 23, 2019 amet sunt laborum ipsum Clara, Michigan',
      categories: ['Air', 'Ocean'],
      tags: ['Export', 'Import'],
      featuredImageId: 20,
      images: [
        {
          id: 0,
          url: 'assets/images/ecommerce/braies-lake.jpg',
          type: 'image'
        },
        {
          id: 1,
          url: 'assets/images/ecommerce/reaching.jpg',
          type: 'image'
        }
      ],
      priceTaxExcl: '516.74',
      priceTaxIncl: '381.49',
      taxRate: 39,
      comparedPrice: '541.16',
      quantity: 6,
      sku: 'directions_boat',
      width: 28,
      height: 89,
      depth: 7,
      weight: '39.13',
      extraShippingFee: '6.24',
      active: true
    },
    {
      id: 21,
      name: 'Earline Wynn',
      transferee: 'Kristina Reese',
      counselor: 'Price Nunez',
      mode: 'GROUND',
      reg_num: 665390,
      group_code: '5cd1e5b8a0e485a7f9116466',
      service: 'IMPORT',
      corp_acc: 'OVERSEAS AGENT BOOKING(128423)',
      date_entered: 'Sunday, April 14, 2019 1:45 PM',
      estimated_weight: '528.4',
      gross_weight: 609,
      handle: 'Rosine-Kansas',
      origin_agent: 'ALL AMERICAN RELOCATION INC',
      origin_location: 'Kiskimere, Montana',
      origin_pack_date: 'February 7, 2019',
      origin_load_date: 'February 10, 2019',
      dest_agent: 'PACKIMPEX MOVING LTD',
      dest_location: 'Bowie, Vermont',
      dest_delivery_date: 'March 28, 2019',
      dest_storage_date: 'April 12, 2019',
      tracking_info: [
        {
          id: 0,
          date: 'Monday, February 18, 2019 6:39 PM',
          descr: 'Sunt exercitation irure aute qui velit deserunt sunt labore.'
        }
      ],
      description:
        'January 6, 2019 excepteur ullamco laborum esse Nescatunga, Iowa',
      categories: ['Air', 'Ocean'],
      tags: ['Export', 'Import'],
      featuredImageId: 21,
      images: [
        {
          id: 0,
          url: 'assets/images/ecommerce/fall-glow.jpg',
          type: 'image'
        },
        {
          id: 1,
          url: 'assets/images/ecommerce/lago-di-braies.jpg',
          type: 'image'
        }
      ],
      priceTaxExcl: '672.27',
      priceTaxIncl: '418.29',
      taxRate: 36,
      comparedPrice: '182.06',
      quantity: 45,
      sku: 'local_shipping',
      width: 45,
      height: 4,
      depth: 31,
      weight: '2.27',
      extraShippingFee: '7.46',
      active: false
    },
    {
      id: 22,
      name: 'Viola Strickland',
      transferee: 'Benson Guzman',
      counselor: 'Giles Sanchez',
      mode: 'GROUND',
      reg_num: 805709,
      group_code: '5cd1e5b8db5b3e6d29b61f6a',
      service: 'EXPORT',
      corp_acc: 'OVERSEAS AGENT BOOKING(128423)',
      date_entered: 'Thursday, February 28, 2019 2:12 AM',
      estimated_weight: '806.4',
      gross_weight: '900.4',
      handle: 'Axis-New Jersey',
      origin_agent: 'ALL AMERICAN RELOCATION INC',
      origin_location: 'Rodman, Federated States Of Micronesia',
      origin_pack_date: 'February 18, 2019',
      origin_load_date: 'April 23, 2019',
      dest_agent: 'PACKIMPEX MOVING LTD',
      dest_location: 'Garnet, South Carolina',
      dest_delivery_date: 'January 16, 2019',
      dest_storage_date: 'February 10, 2019',
      tracking_info: [
        {
          id: 0,
          date: 'Monday, February 11, 2019 3:25 AM',
          descr:
            'Incididunt eiusmod sint et excepteur culpa officia ex cillum excepteur elit.'
        }
      ],
      description:
        'January 16, 2019 labore incididunt eu consectetur Chestnut, Maryland',
      categories: ['Air', 'Ocean'],
      tags: ['Export', 'Import'],
      featuredImageId: 22,
      images: [
        {
          id: 0,
          url: 'assets/images/ecommerce/a-walk-amongst-friends.jpg',
          type: 'image'
        },
        {
          id: 1,
          url: 'assets/images/ecommerce/a-walk-amongst-friends.jpg',
          type: 'image'
        }
      ],
      priceTaxExcl: '74.98',
      priceTaxIncl: '1.8',
      taxRate: 12,
      comparedPrice: '528.25',
      quantity: 88,
      sku: 'local_shipping',
      width: 45,
      height: 21,
      depth: 44,
      weight: '98.75',
      extraShippingFee: '7.32',
      active: true
    },
    {
      id: 23,
      name: 'Malinda Small',
      transferee: 'Briggs Velasquez',
      counselor: 'Cecelia Rosa',
      mode: 'GROUND',
      reg_num: 270644,
      group_code: '5cd1e5b88ac7d431685755b0',
      service: 'IMPORT',
      corp_acc: 'OVERSEAS AGENT BOOKING(128423)',
      date_entered: 'Wednesday, February 27, 2019 2:06 AM',
      estimated_weight: '619.1',
      gross_weight: 636,
      handle: 'Morriston-Marshall Islands',
      origin_agent: 'ALL AMERICAN RELOCATION INC',
      origin_location: 'Fairhaven, Georgia',
      origin_pack_date: 'January 11, 2019',
      origin_load_date: 'February 14, 2019',
      dest_agent: 'PACKIMPEX MOVING LTD',
      dest_location: 'Woodburn, Idaho',
      dest_delivery_date: 'April 20, 2019',
      dest_storage_date: 'April 11, 2019',
      tracking_info: [
        {
          id: 0,
          date: 'Saturday, January 5, 2019 10:02 AM',
          descr:
            'Irure culpa amet culpa anim excepteur do proident pariatur deserunt tempor.'
        }
      ],
      description:
        'January 19, 2019 mollit id deserunt consequat Enlow, Delaware',
      categories: ['Air', 'Ocean'],
      tags: ['Export', 'Import'],
      featuredImageId: 23,
      images: [
        {
          id: 0,
          url: 'assets/images/ecommerce/braies-lake.jpg',
          type: 'image'
        },
        {
          id: 1,
          url: 'assets/images/ecommerce/lago-di-braies.jpg',
          type: 'image'
        }
      ],
      priceTaxExcl: '524.24',
      priceTaxIncl: '359.08',
      taxRate: 17,
      comparedPrice: '299.56',
      quantity: 8,
      sku: 'local_shipping',
      width: 42,
      height: 81,
      depth: 70,
      weight: '48.97',
      extraShippingFee: '5.11',
      active: true
    },
    {
      id: 24,
      name: 'Hannah Sosa',
      transferee: 'Kent Turner',
      counselor: 'Reva Wilcox',
      mode: 'SEA',
      reg_num: 684073,
      group_code: '5cd1e5b84ac8fd938f2ff44c',
      service: 'IMPORT',
      corp_acc: 'OVERSEAS AGENT BOOKING(128423)',
      date_entered: 'Thursday, February 28, 2019 2:30 AM',
      estimated_weight: '537.6',
      gross_weight: '636.1',
      handle: 'Cedarville-North Dakota',
      origin_agent: 'ALL AMERICAN RELOCATION INC',
      origin_location: 'Fairmount, Illinois',
      origin_pack_date: 'January 9, 2019',
      origin_load_date: 'May 5, 2019',
      dest_agent: 'PACKIMPEX MOVING LTD',
      dest_location: 'Finzel, Alaska',
      dest_delivery_date: 'January 12, 2019',
      dest_storage_date: 'February 6, 2019',
      tracking_info: [
        {
          id: 0,
          date: 'Sunday, January 13, 2019 3:04 PM',
          descr:
            'Anim qui ex consectetur aliquip commodo consequat dolor nisi officia laboris exercitation laborum aliquip.'
        }
      ],
      description: 'March 10, 2019 sunt in cupidatat sit Sharon, Nevada',
      categories: ['Air', 'Ocean'],
      tags: ['Export', 'Import'],
      featuredImageId: 24,
      images: [
        {
          id: 0,
          url: 'assets/images/ecommerce/morain-lake.jpg',
          type: 'image'
        },
        {
          id: 1,
          url: 'assets/images/ecommerce/braies-lake.jpg',
          type: 'image'
        }
      ],
      priceTaxExcl: '423.9',
      priceTaxIncl: '387.17',
      taxRate: 15,
      comparedPrice: '474.84',
      quantity: 41,
      sku: 'directions_boat',
      width: 5,
      height: 71,
      depth: 39,
      weight: '74.01',
      extraShippingFee: '6.28',
      active: false
    },
    {
      id: 25,
      name: 'Gaines Holden',
      transferee: 'Beth Schroeder',
      counselor: 'Cannon Morrow',
      mode: 'GROUND',
      reg_num: 899044,
      group_code: '5cd1e5b8d3edf8202f964457',
      service: 'IMPORT',
      corp_acc: 'OVERSEAS AGENT BOOKING(128423)',
      date_entered: 'Wednesday, February 27, 2019 7:18 PM',
      estimated_weight: '723.3',
      gross_weight: '262.6',
      handle: 'Westwood-West Virginia',
      origin_agent: 'ALL AMERICAN RELOCATION INC',
      origin_location: 'Catherine, Indiana',
      origin_pack_date: 'April 2, 2019',
      origin_load_date: 'February 15, 2019',
      dest_agent: 'PACKIMPEX MOVING LTD',
      dest_location: 'Ruckersville, Oregon',
      dest_delivery_date: 'January 14, 2019',
      dest_storage_date: 'February 4, 2019',
      tracking_info: [
        {
          id: 0,
          date: 'Thursday, February 7, 2019 6:18 PM',
          descr: 'Velit enim eu sunt dolore commodo Lorem.'
        }
      ],
      description:
        'January 17, 2019 incididunt aliqua dolore sit Trinway, Rhode Island',
      categories: ['Air', 'Ocean'],
      tags: ['Export', 'Import'],
      featuredImageId: 25,
      images: [
        {
          id: 0,
          url: 'assets/images/ecommerce/lago-di-sorapis.jpg',
          type: 'image'
        },
        {
          id: 1,
          url: 'assets/images/ecommerce/first-snow.jpg',
          type: 'image'
        }
      ],
      priceTaxExcl: '860.3',
      priceTaxIncl: '166.26',
      taxRate: 37,
      comparedPrice: '493.19',
      quantity: 11,
      sku: 'local_shipping',
      width: 11,
      height: 21,
      depth: 33,
      weight: '80.96',
      extraShippingFee: '7.28',
      active: true
    },
    {
      id: 26,
      name: 'Cherie Bullock',
      transferee: 'Whitley Jensen',
      counselor: 'Ramona Gill',
      mode: 'AIR',
      reg_num: 706766,
      group_code: '5cd1e5b8d2f89615bb31a4d9',
      service: 'IMPORT',
      corp_acc: 'OVERSEAS AGENT BOOKING(128423)',
      date_entered: 'Tuesday, April 23, 2019 5:47 PM',
      estimated_weight: '203.8',
      gross_weight: '788.7',
      handle: 'Goodville-California',
      origin_agent: 'ALL AMERICAN RELOCATION INC',
      origin_location: 'Ola, New York',
      origin_pack_date: 'January 3, 2019',
      origin_load_date: 'May 3, 2019',
      dest_agent: 'PACKIMPEX MOVING LTD',
      dest_location: 'Nash, Connecticut',
      dest_delivery_date: 'January 16, 2019',
      dest_storage_date: 'January 23, 2019',
      tracking_info: [
        {
          id: 0,
          date: 'Tuesday, May 7, 2019 5:10 PM',
          descr:
            'Dolore exercitation nostrud minim dolore qui quis cillum sint qui Lorem.'
        }
      ],
      description:
        'April 16, 2019 consectetur amet laboris fugiat Hailesboro, Arkansas',
      categories: ['Air', 'Ocean'],
      tags: ['Export', 'Import'],
      featuredImageId: 26,
      images: [
        {
          id: 0,
          url: 'assets/images/ecommerce/yosemite.jpg',
          type: 'image'
        },
        {
          id: 1,
          url: 'assets/images/ecommerce/yosemite.jpg',
          type: 'image'
        }
      ],
      priceTaxExcl: '727.35',
      priceTaxIncl: '936.35',
      taxRate: 6,
      comparedPrice: '977.22',
      quantity: 61,
      sku: 'flight_takeoff',
      width: 4,
      height: 49,
      depth: 22,
      weight: '92.68',
      extraShippingFee: '6.1',
      active: false
    },
    {
      id: 27,
      name: 'Estella Snyder',
      transferee: 'Chambers Henry',
      counselor: 'Kirsten Tyson',
      mode: 'GROUND',
      reg_num: 166390,
      group_code: '5cd1e5b8dc2c019115d4baad',
      service: 'EXPORT',
      corp_acc: 'OVERSEAS AGENT BOOKING(128423)',
      date_entered: 'Saturday, March 9, 2019 6:37 AM',
      estimated_weight: '917.2',
      gross_weight: '257.3',
      handle: 'Harleigh-South Dakota',
      origin_agent: 'ALL AMERICAN RELOCATION INC',
      origin_location: 'Dragoon, Northern Mariana Islands',
      origin_pack_date: 'January 14, 2019',
      origin_load_date: 'February 22, 2019',
      dest_agent: 'PACKIMPEX MOVING LTD',
      dest_location: 'Valmy, Virgin Islands',
      dest_delivery_date: 'April 8, 2019',
      dest_storage_date: 'February 23, 2019',
      tracking_info: [
        {
          id: 0,
          date: 'Monday, January 14, 2019 5:08 PM',
          descr:
            'Magna esse irure reprehenderit aliqua aliqua nisi reprehenderit.'
        }
      ],
      description: 'April 6, 2019 elit proident incididunt quis Foxworth, Utah',
      categories: ['Air', 'Ocean'],
      tags: ['Export', 'Import'],
      featuredImageId: 27,
      images: [
        {
          id: 0,
          url: 'assets/images/ecommerce/fall-glow.jpg',
          type: 'image'
        },
        {
          id: 1,
          url: 'assets/images/ecommerce/never-stop-changing.jpg',
          type: 'image'
        }
      ],
      priceTaxExcl: '628.23',
      priceTaxIncl: '958.23',
      taxRate: 2,
      comparedPrice: '309.84',
      quantity: 95,
      sku: 'local_shipping',
      width: 87,
      height: 51,
      depth: 76,
      weight: '98.1',
      extraShippingFee: '6.38',
      active: false
    },
    {
      id: 28,
      name: 'Estelle Acosta',
      transferee: 'Marion Charles',
      counselor: 'Cynthia Michael',
      mode: 'SEA',
      reg_num: 937227,
      group_code: '5cd1e5b82af43fe69873ad9c',
      service: 'EXPORT',
      corp_acc: 'OVERSEAS AGENT BOOKING(128423)',
      date_entered: 'Tuesday, February 5, 2019 6:34 AM',
      estimated_weight: '757.3',
      gross_weight: '278.3',
      handle: 'Franklin-Oklahoma',
      origin_agent: 'ALL AMERICAN RELOCATION INC',
      origin_location: 'Sunwest, Virginia',
      origin_pack_date: 'January 11, 2019',
      origin_load_date: 'January 12, 2019',
      dest_agent: 'PACKIMPEX MOVING LTD',
      dest_location: 'Stewart, New Mexico',
      dest_delivery_date: 'May 1, 2019',
      dest_storage_date: 'March 31, 2019',
      tracking_info: [
        {
          id: 0,
          date: 'Monday, March 4, 2019 5:04 AM',
          descr:
            'Aliquip excepteur sit dolore adipisicing adipisicing est irure ut tempor.'
        }
      ],
      description: 'April 17, 2019 tempor nisi ea magna Homeworth, Mississippi',
      categories: ['Air', 'Ocean'],
      tags: ['Export', 'Import'],
      featuredImageId: 28,
      images: [
        {
          id: 0,
          url: 'assets/images/ecommerce/a-walk-amongst-friends.jpg',
          type: 'image'
        },
        {
          id: 1,
          url: 'assets/images/ecommerce/never-stop-changing.jpg',
          type: 'image'
        }
      ],
      priceTaxExcl: '41.26',
      priceTaxIncl: '527.52',
      taxRate: 39,
      comparedPrice: '359.01',
      quantity: 3,
      sku: 'directions_boat',
      width: 31,
      height: 63,
      depth: 30,
      weight: '27.07',
      extraShippingFee: '2.51',
      active: true
    }
  ];
}
