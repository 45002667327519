import { WebConfig } from "@shared/core/models/configuration/web-config";

/**
 * PROD Deployment
 */
export const environment: WebConfig = {
    production: true,
    hmr: false,
    //These properties are loaded in configuration.service.ts and auth-configuration.service.ts
    sts: null,
    tracking: null,
    auth: null,
    platform: null,
    jobs: null,
    external: null,
    sgl: null,
    featureFlags: null
}
