import { HttpClient, HttpHeaders } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { environment } from "@env/environment"
import { OidcSecurityService } from "angular-auth-oidc-client"
import { Observable } from "rxjs"
import { shareReplay, tap } from "rxjs/operators"
import { WebConfig } from "../models/configuration/web-config"

@Injectable({
    providedIn: 'root'
})
export class ConfigurationService {
    private configuration$: Observable<WebConfig>

    constructor(private _httpClient: HttpClient, private _oidcSecurityService: OidcSecurityService) {}

    loadConfiguration(): Observable<WebConfig> {
        const requestUrl = `${this.getBaseUrl()}/api/v1/Configuration`
        const tokenId = this._oidcSecurityService.getToken()

        if (!this.configuration$) {
            this.configuration$ = this._httpClient.get<WebConfig>(requestUrl, {
                headers: this.getRequestHeaders(tokenId)
            }).pipe(
                tap(configuration => this.setEnvironment(configuration)),
                shareReplay(1)
            )
        }

        return this.configuration$
    }

    private getBaseUrl() {
        if(window.location.origin.includes("localhost")) {
            return 'https://localhost:44369'
        } else if (window.location.origin.includes("uatsimpl.suddath.com")){            
            return window.location.origin.replace("uatsimpl.suddath.com","uaue2sgljobswa01.azurewebsites.net");
        } else if (window.location.origin.includes("simpl.suddath.com")) {
            return window.location.origin.replace("simpl.suddath.com","paue2sgljobswa01.azurewebsites.net");
        }  else if (window.location.origin.includes("simpl-staging.suddath.com")) {
            return window.location.origin.replace("simpl-staging.suddath.com","paue2sgljobswa01-staging.azurewebsites.net");
        } else {
            return window.location.origin.replace("portalwb","jobswa");
        }
    }

    private getRequestHeaders(tokenId: string) {
        return new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + tokenId
        })
    }

    private setEnvironment(configuration: WebConfig) {
        Object.assign(environment, configuration)
    }
}
