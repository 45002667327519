<div class="card pt-4 ml-4 mr-4 pl-4 pr-lg-4" style="margin-top: 150px">
   <!-- Jumbotron -->
   <div class="jumbotron text-center blue-grey lighten-5">

      <!-- Title -->
      <h2 class="card-title h2">{{ support.title }}</h2>

      <!-- Subtitle -->
      <p class="indigo-text my-4 font-weight-bold">{{ support.subTitle }}</p>

      <!-- Grid row -->
      <div class="row d-flex justify-content-center">

         <!-- Grid column -->
         <div class="col-xl-7 pb-2 mt-5">

            <p class="card-text">Possible solutions:</p>
            <div class="list-group-flush">
               <div class="list-group-item">
                  <p class="mb-0">
                     <i class="far fa-user fa-2x mr-4 grey p-3 white-text rounded-circle " aria-hidden="true"></i>
                     <!-- Make sure you are using correct credentials.-->
                     {{ support.solutionOne }}
                  </p>
               </div>
               <div class="list-group-item">
                  <p class="mb-0">
                     <i class="fas fa-phone fa-2x mr-4 mr-4 grey p-3 white-text rounded-circle" aria-hidden="true"></i>
                     <!-- Contact us: (888)-799-5033-->
                     {{ support.solutionTwo }}
                  </p>
               </div>
            </div>
         </div>
         <!-- Grid column -->

      </div>
      <!-- Grid row -->

      <hr class="my-4 pb-2">

      <div *ngIf="id == 100">
         <button
            class="btn btn-primary btn-rounded"
            (click)="login()">
            <i class="fas fa-sign-out-alt mr-1"></i>
            Logout
         </button>
      </div>
      <div *ngIf="id == 101">
         <button
            class="btn btn-primary btn-rounded"
            (click)="logout()">
            <i class="fas fa-sign-out-alt mr-1"></i>
            Login
         </button>
      </div>

   </div>
   <!-- Jumbotron -->
</div>
