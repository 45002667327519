import { Component, OnInit, OnDestroy } from '@angular/core'
import { Router } from '@angular/router'
import { SuddathAuthenticationService } from '@suddath/authentication'
import { SuddathAuthorizationService } from '@suddath/authorization'
import { Observable, Subscription } from 'rxjs'
import { map } from 'rxjs/operators'

@Component({
    selector: 'app-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit, OnDestroy {
    currentUserRoleNamesSubscription: Subscription
    currentUserRoleNames: any[] = this._suddathAuthorizationService.userRoles ?? []
    isAuth: boolean = this._userService.isAuthorized

    get assumedPrimaryRole(): string {
        return this.currentUserRoleNames.length
            ? this.currentUserRoleNames[0]
            : 'Has.Not.Signed.In'
    }

    constructor(private _userService: SuddathAuthenticationService,
        private _router: Router,
        private _suddathAuthorizationService: SuddathAuthorizationService
    ) { }

    private getRouteByRole(role: string = ''): string[] {
        const roleRoutes = {
            'Has.Not.Signed.In': ['/ui/landing/home'],
            'Operations.User.Role': ['/apps/sglclientportal/orders'],
            'Operations.SuperUser.Role': ['/apps/sglclientportal/orders'],
            'Franchisee.User.Role': ['/apps/sglclientportal/orders'],
            'Account.User.Role': ['/apps/sglclientportal/orders'],
            'Integrator.User.Role': ['/apps/sglclientportal/orders']
        }

        return roleRoutes[role] ? roleRoutes[role] : ['/support', 100]
    }

    /**
     * ngOnInit - set initial timeout to enable role routing
     */
    ngOnInit() {
        const timeout = setTimeout(() => {
            this._router.navigate(this.getRouteByRole(this.assumedPrimaryRole))
        }, 5000)

        this._userService.isAuthorized$.subscribe(authorized => {
            if (authorized) {
                this._userService.currentUser$.subscribe(user => {
                    if (user) {
                        this.currentUserRoleNamesSubscription = this._suddathAuthorizationService.getUserRoles()
                            .pipe(map(roleNames => (this.currentUserRoleNames = roleNames?.roles.map(role => role?.name))))
                            .subscribe(() => {
                                clearTimeout(timeout)
                                this._router.navigate(
                                    this.getRouteByRole(this.assumedPrimaryRole)
                                )
                            })
                    }
                })
            }
        })
    }

    ngOnDestroy() {
        this.currentUserRoleNamesSubscription?.unsubscribe()
    }
}
