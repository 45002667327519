import { MDBBootstrapModulesPro } from 'ng-uikit-pro-standard'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { NavigationComponent } from './navigation.component'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { MatSelectModule } from '@angular/material/select'
import { MatInputModule } from '@angular/material/input'
import { MatFormFieldModule } from '@angular/material/form-field'

@NgModule({
   imports: [
      CommonModule,
      RouterModule,
      FormsModule,
      ReactiveFormsModule,
      MDBBootstrapModulesPro.forRoot(),
      FontAwesomeModule,
      MatSelectModule,
      MatInputModule,
      MatFormFieldModule
   ],
   declarations: [
      NavigationComponent
   ],
   exports: [
      NavigationComponent
   ],
   providers: [
   ]
})
export class NavigationModule {

}
