export class ShipmentFakeDb {
   
   public static shipmentData = [
      {
         id     : 12312312,
         address: 'Washington DC -> Amsterdam',
         mode   : 'Sea',
         created: 'Jul 25, 2019',
         status : 'completed'
      },
      {
         id     : 12312313,
         address: 'Amsterdam -> Paris',
         mode   : 'Air',
         created: 'Jul 25, 2019',
         status : 'completed'
      },

   ]
}