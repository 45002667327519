<div class="container">
    <div class="row">
        <div class="col text-center pad-top-half">
            <h3>Welcome to SIMPL</h3>
            <div
                class="spinner-border spinner-border-sm m-5"
                role="status"
                style="width: 3rem; height: 3rem;"
            >
                <span class="sr-only">&nbsp;</span>
            </div>
        </div>
    </div>
</div>
