import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { OidcSecurityService } from "angular-auth-oidc-client";
import { Observable } from "rxjs";
import { OrderType } from "../models/order-type";
import { SglProject } from "../models/project";
import { SglProjectOption } from "../models/project-option";
import { TimelineStep } from "../models/timeline-step";

@Injectable({
    providedIn: 'root'
})
export class ProjectsService {

    constructor(
        private oidcSecurityService: OidcSecurityService,
        private httpClient: HttpClient
    ) { }

    getProject(projectId: number): Observable<SglProject> {
        const requestUrl = `${this.getProjectsBaseUrl()}/${projectId}`
        const requestHeaders = this.getRequestHeaders();

        return this.httpClient.get<SglProject>(requestUrl, { headers: requestHeaders })
    }

    editProject(project: SglProject): Observable<any> {
        const requestUrl = this.getProjectsBaseUrl()
        const requestHeaders = this.getRequestHeaders();

        return this.httpClient.put(requestUrl, project, { headers: requestHeaders })
    }

    deleteProject(projectId: number): Observable<SglProject> {
        const requestUrl = `${this.getProjectsBaseUrl()}/${projectId}`
        const requestHeaders = this.getRequestHeaders();

        return this.httpClient.delete<SglProject>(requestUrl, { headers: requestHeaders })
    }

    restoreProject(projectId: number): Observable<any> {
        const requestUrl = `${this.getProjectsBaseUrl()}/${projectId}/restore`
        const requestHeaders = this.getRequestHeaders();

        return this.httpClient.post(requestUrl, null, { headers: requestHeaders })
    }

    getProjectOption(projectId: number): Observable<SglProjectOption> {
        const requestUrl = `${this.getProjectsBaseUrl()}/${projectId}/ProjectOption`
        const requestHeaders = this.getRequestHeaders();

        return this.httpClient.get<SglProjectOption>(requestUrl, { headers: requestHeaders })
    }

    editProjectOption(projectOption: SglProjectOption): Observable<any> {
        const requestUrl = `${this.getProjectsBaseUrl()}/${projectOption.projectId}/ProjectOption`
        const requestHeaders = this.getRequestHeaders();

        return this.httpClient.put(requestUrl, projectOption, { headers: requestHeaders })
    }

    downloadProductsFile(projectId: number): Observable<Blob> {
        const requestUrl = `${this.getProjectsBaseUrl()}/${projectId}/downloadproductsfile`
        const requestHeaders = this.getRequestHeaders();
        
        return this.httpClient.get(requestUrl, {
            headers: requestHeaders,
            responseType: 'blob'
        })
    }

    uploadProductsFile(projectId: number, file: File): Observable<any> {
        const requestUrl = `${this.getProjectsBaseUrl()}/${projectId}/uploadproductsfile`
        const requestHeaders = this.getRequestAuthHeaders();

        const formData = new FormData()
        formData.append('file', file, file.name)

        return this.httpClient.post(requestUrl, formData, { headers: requestHeaders })
    }

    assignCoordinators(
        projectId: number,
        coordinatorId: number,
        locationIdentifier: string,
        address1: string,
        city: string,
        state: string,
        zip: string,
        userDefined: string,
        coordinatorName: string
    ): Observable<any> {
        const requestUrl = `${this.getProjectsBaseUrl()}/assigncoordinators`
        const requestHeaders = this.getRequestHeaders()

        return this.httpClient.post(requestUrl, {
            projectId,
            coordinatorId,
            locationIdentifier,
            address1,
            city,
            state,
            zip,
            userDefined,
            coordinatorName
        }, {
            headers: requestHeaders
        })
    }

    assignIntegrators(
        projectId: number,
        integratorId: number,
        locationIdentifier: string,
        address1: string,
        city: string,
        state: string,
        zip: string,
        userDefined: string,
        integratorName: string
    ): Observable<any> {
        const requestUrl = `${this.getProjectsBaseUrl()}/assignintegrators`
        const requestHeaders = this.getRequestHeaders()

        return this.httpClient.post(requestUrl, {
            projectId,
            integratorId,
            locationIdentifier,
            address1,
            city,
            state,
            zip,
            userDefined,
            integratorName
        }, {
            headers: requestHeaders
        })
    }

    containsNonDirectOrders(projectId: number): Observable<boolean> {
        const requestUrl = `${this.getProjectsBaseUrl()}/${projectId}/ContainsNonDirectOrders`
        const requestHeaders = this.getRequestHeaders();

        return this.httpClient.get<boolean>(requestUrl, { headers: requestHeaders })
    }

    getTimelineSteps(projectId: number, orderType: OrderType): Observable<TimelineStep[]> {
        const requestUrl = `${this.getProjectsBaseUrl()}/${projectId}/TimelineSteps/${orderType}`
        const requestHeaders = this.getRequestHeaders();

        return this.httpClient.get<TimelineStep[]>(requestUrl, { headers: requestHeaders })
    }

    editTimelineSteps(projectId: number, orderType: OrderType, timelineSteps: TimelineStep[]): Observable<any> {
        const requestUrl = `${this.getProjectsBaseUrl()}/${projectId}/TimelineSteps/${orderType}`
        const requestHeaders = this.getRequestHeaders();

        return this.httpClient.put(requestUrl, timelineSteps, { headers: requestHeaders })
    }

    private getProjectsBaseUrl() {
        return `${environment.sgl.base_url}projects`
    }

    private getRequestHeaders() {
        return new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.oidcSecurityService.getToken()}`
        })
    }

    private getRequestAuthHeaders() {
        return new HttpHeaders({
            Authorization: `Bearer ${this.oidcSecurityService.getToken()}`
        })
    }
}
